import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CasesItem from "../../components/cases";

SwiperCore.use([Pagination]);
const ServiceDetailsContainer = ({ data }) => {
    const { t } = useTranslation();
    const swiperOption = {
        slidesPerView: 2,
        speed: 1000,
        loop: true,
        spaceBetween: 30,
        autoplay: false,
        pagination: { clickable: true },
        breakpoints: {
            1200: {
                slidesPerView: 2,
            },

            991: {
                slidesPerView: 2,
            },

            767: {
                slidesPerView: 2,
            },

            576: {
                slidesPerView: 2,
            },

            0: {
                slidesPerView: 1,
            },
        },
    };
    const fieldNames = [
        "establishment",
        "address",
        "town",
        "telephone",
        "license",
        "license_status",
        "type",
        "sub_type",
        "contact_person",
        "license_type",
        "license_expiration",
        "capacity",
    ];
    return (
        <section className="department-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="department-wrpp">
                            <div className="sidebar-wrapper ">
                                {/* <DepartmentWidget /> */}
                                {/* <SidebarWorkingTime data={data} /> */}
                                {/* <Appointment /> */}
                                <div className="widget-item">
                                    <div className="widget-work-hours card shadow bg-light">
                                        <div>
                                            <div className="thumb text-center">
                                                <img
                                                    src={data.image}
                                                    alt="Avanzando juntos"
                                                    width={242}
                                                    height={242}
                                                    className="mb-3 w-100"
                                                    style={{
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </div>
                                            <div className="membr-info">
                                                <h5 className="name capitalizing text-center">
                                                    {data.establishment}
                                                </h5>
                                                <h6 className="designation text-center mb-3">
                                                    {data.email}
                                                </h6>
                                                <div className="text-center">
                                                    <Link
                                                        to={`/chat/${data.id}`}
                                                    >
                                                        <button className="btn btn-outline-primary w-100 p-2  btn-lg">
                                                            Message
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="department-content card shadow bg-light p-3 mt-3">
                                {/* <ServiceDetails data={data} /> */}
                                <div className="membr-info ">
                                    <h2 className="title">
                                        <span>Personal</span> Info
                                    </h2>
                                    <div className="row">
                                        {fieldNames?.map((e) => (
                                            <div className="col-md-4">
                                                <label className="text-capitalize">
                                                    {t(e).replace(/_/g, " ")}
                                                </label>
                                                <input
                                                    className="form-control mb-3"
                                                    readOnly
                                                    value={data?.[e]}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="case-area">
                                    <h2 className="title">
                                        <span>{t("documents")}</span>
                                    </h2>
                                    <Swiper
                                        className="case-slider-container"
                                        {...swiperOption}
                                    >
                                        {data?.media &&
                                            data?.media.map((single, key) => {
                                                return (
                                                    <SwiperSlide key={key}>
                                                        <CasesItem
                                                            key={key}
                                                            data={single}
                                                        />
                                                    </SwiperSlide>
                                                );
                                            })}
                                    </Swiper>
                                    <div className="float-end">
                                        <Link
                                            to={`/building/${data.id}/${data.establishment}`}
                                        >
                                            <button className="btn btn-primary">
                                                Book Now{" "}
                                                <i className="icofont-bed" />
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ServiceDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default ServiceDetailsContainer;
