import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import second from "../assets/img/02.jpg";
import elder1 from "../assets/img/eldery1.jpeg";
import elder2 from "../assets/img/eldery2.jpeg";
import ScrollToTop from "../components/scroll-to-top";
import SectionTitle from "../components/section-title";
import SEO from "../components/seo";
import { axiosInstance } from "../config/https";
import CallToAction from "../containers/global/call-to-action/index.jsx";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import ServiceContainer from "../containers/service-box/index.jsx";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index.jsx";

SwiperCore.use([Pagination]);

const ServicePage = () => {
    const swiperOption = {
        speed: 600,
        spaceBetween: 30,
        slidesPerView: 3,
        pagination: { clickable: true },
        breakpoints: {
            1200: {
                slidesPerView: 3,
            },

            991: {
                slidesPerView: 2,
            },

            767: {
                slidesPerView: 2,
            },

            560: {
                slidesPerView: 2,
            },

            0: {
                slidesPerView: 1,
            },
        },
    };
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [carehomes, setCarehomes] = useState([]);
    const getcarehomes = () => {
        setLoader(true);
        axiosInstance
            .get("carehomes")
            .then((response) => {
                const Data = response.data.data;
                console.log(response);
                if (response.data.status === true) {
                    setCarehomes(Data);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((e) => {
                setLoader(false);
                console.log(e, "<======error");
            });
    };
    useEffect(() => {
        getcarehomes();
    }, []);
    return (
        <React.Fragment>
            {loader ? <div className="loader"></div> : null}
            <Layout>
                <SEO title="Avanzando juntos – Service" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal capitalizing">
                        <PageTitleContainer
                            image={"/img/bgnurse.png"}
                            subTitle={t(
                                `register_your_establishment_with_us_today`
                            ).replace(/_/g, " ")}
                            title={`<span>${t(
                                `establishment_provided_in_search_are_ones_registered_with_the_state.`
                            ).replace(/_/g, " ")}</span>`}
                        />
                        <section className=" bg-white mt-5">
                            <div className="container mb-5">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div
                                            className="row icon-box-style"
                                            data-aos="fade-up"
                                            data-aos-duration="1100"
                                        >
                                            <div className="row">
                                                <p className="capitalizing text-center home-text fs-5">
                                                    {t(
                                                        `avanzando_juntos_emerged_from_the_need_i_perceived_in_senior_care_establishments_where_i_worked._i_witnessed_firsthand_how_families_felt_overwhelmed_when_trying_to_find_a_healthcare_provider_or_establishment_for_their_loved_ones._this_process_is_already_difficult_in_itself,_and_without_an_adequate_and_organized_system_that_gathers_all_necessary_information_in_one_place,_it_becomes_a_tiring,_complicated,_and_costly_task._from_the_effort_and_desperation_of_families,_avanzando_juntos_corporation_was_born,_an_online_platform_designed_to_gather_information,_facilitate_the_service_search_process,_and_provide_resources_regarding_the_rights_of_both_families_and_patients.`
                                                    ).replace(/_/g, " ")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <SectionTitle
                                            classOption="text-center capitalizing"
                                            subTitle={t(`our_services`).replace(
                                                /_/g,
                                                " "
                                            )}
                                            title={`<span>${t(
                                                `search_for_establishments_`
                                            ).replace(/_/g, " ")}</span> ${t(
                                                "using_our_free_search_tool_below!"
                                            ).replace(/_/g, " ")}
                                            `}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ServiceContainer />
                        <section className=" bg-white my-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <SectionTitle
                                            classOption="text-center capitalizing"
                                            title={`<span>${t(
                                                `for_your_family’s_health_and_yours,`
                                            ).replace(/_/g, " ")}</span> ${t(
                                                `_the_best_care_homes!`
                                            ).replace(/_/g, " ")}`}
                                        />
                                        <div
                                            className="row icon-box-style"
                                            data-aos="fade-up"
                                            data-aos-duration="1100"
                                        >
                                            <Swiper
                                                className="team-slider-container"
                                                {...swiperOption}
                                            >
                                                {carehomes.length > 0 ? (
                                                    carehomes
                                                        .filter(
                                                            (s) =>
                                                                s.is_featured ===
                                                                1
                                                        )
                                                        .map((e) => (
                                                            <SwiperSlide>
                                                                <Link
                                                                    to={`/service-details/${e.id}`}
                                                                >
                                                                    <img
                                                                        src={
                                                                            e.image
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                            height: 200,
                                                                        }}
                                                                        className="img-thumbnail w-100 "
                                                                    />
                                                                </Link>
                                                            </SwiperSlide>
                                                        ))
                                                ) : (
                                                    <p className="text-capitailze">
                                                        {" "}
                                                        {t(
                                                            "no_data_found"
                                                        ).replace(/_/g, " ")}
                                                    </p>
                                                )}
                                            </Swiper>
                                        </div>
                                        <div className="text-center mt-5">
                                            <a
                                                href="https://carehomes.avanzandojuntos.net/"
                                                target="_blank"
                                                className="btn btn-outline-warning p-3 capitalizing w-50"
                                            >
                                                <i className="icofont-star"></i>{" "}
                                                {t(
                                                    "featured_your_care_homes"
                                                ).replace(/_/g, " ")}{" "}
                                                <i className="icofont-star"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br />
                        <div
                            className="my-3 container"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h1
                                className="text-center capitalizing fw-light home-heading"
                                style={{ fontSize: 50 }}
                            >
                                {t("list_of_licensed_establishments").replace(
                                    /_/g,
                                    " "
                                )}{" "}
                                <br />
                                <a
                                    href="https://adfan.pr.gov/AdministracionesAuxiliares/CuidadoSustitutoAdopcion/Documents/REG%20CENTROS%20LIC%20CERT%20ADULTOS%20MAYORES%20Y%20ADULTOS%20IMPEDIMENTOS%20-%20AUG192021.pdf"
                                    target="_blank"
                                    className="text-dark d-flex justify-content-center"
                                >
                                    <img
                                        src="/img/pr.png"
                                        className="my-5"
                                        alt=""
                                    />
                                </a>
                            </h1>
                        </div>
                        {/* <TestimonialContainer /> */}
                        {/* <BrandContainer /> */}
                        {/* <PricingContainer pricingClassOption="noborder-line" /> */}

                        <section className=" bg-white my-3 mb-3">
                            <div className="container">
                                <SectionTitle
                                    classOption="text-center capitalizing"
                                    title={`<span>${t("resources")}</span>`}
                                />
                                <div className="row">
                                    <div className="col-md-6">
                                        <a href="https://agencias.pr.gov/agencias/oppea/pages/default.aspx">
                                            <img
                                                src={elder1}
                                                alt=""
                                                width={"100%"}
                                                height={200}
                                                style={{
                                                    height: 300,
                                                }}
                                                className="img-thumbnail"
                                            />
                                        </a>
                                    </div>

                                    <div className="col-md-6">
                                        <a href="https://censo.estadisticas.pr/Comunicado-de-prensa/2022-12-08t122613">
                                            <img
                                                src={elder2}
                                                alt=""
                                                width={"100%"}
                                                style={{
                                                    height: 300,
                                                }}
                                                className="img-thumbnail"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <CallToAction />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ServicePage;
