/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../config/https";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
const Building = () => {
    const history = useHistory();
    useEffect(() => {
        if (!localStorage.getItem("accessToken")) {
            history.push("/Login");
            toast.warn("Login First");
        } else {
        }
    }, []);
    const { t, i18n } = useTranslation();

    const { id } = useParams();
    const { director } = useParams();
    const [loader, setLoader] = useState(false);
    const [beds, setBeds] = useState([]);
    const [bed_id, setBed_id] = useState({ bedno: 0, bedid: 0 });
    const [date, setDate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [endtime, setendtime] = useState("");
    const [starttime, setstarttime] = useState("");
    const [selectedBedIndex, setSelectedBedIndex] = useState(0);
    const [life_time, setLife_time] = useState(0);
    const [modalIsOpen, setIsOpen] = useState(false);

    const handleBedIndexChange = (event) => {
        setSelectedBedIndex(parseInt(event.target.value));
    };

    // Filter beds with status === 1 to get valid indices
    const validBedIndices = beds
        .map((bed, index) => (bed.status === 1 ? index : null))
        .filter((index) => index !== null);

    // Set default selected bed to Bed 1 (index 0) on initial render
    const getBeds = () => {
        setLoader(true);
        axiosInstance
            .get(`carehomes/${id}`)
            .then(function (response) {
                console.log(response, "floors");
                if (response?.data.status === true) {
                    setBeds(response?.data?.data?.buildings[0].floors);
                    setLoader(false);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                console.log(error, "rorr");
                setLoader(false);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            });
    };
    useEffect(() => {
        setSelectedBedIndex(0);
    }, []);

    useEffect(() => {
        getBeds();
    }, []);
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
        },
    };
    const Booknow = () => {
        setIsOpen(false);
        setLoader(true);
        const formdata = new FormData();
        formdata.append("bed_id", bed_id.bedid);
        formdata.append("date", date);
        formdata.append("end_date", enddate);
        formdata.append("start_time", starttime);
        formdata.append("end_time", endtime);
        formdata.append("life_time", life_time);
        axiosInstance
            .post(`user/book_bed`, formdata)
            .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    toast.success(response.data.response);
                    getBeds();
                    setIsOpen(false);
                    setLoader(false);
                } else {
                    setLoader(false);
                    toast.error("response.data.response");
                }
            })
            .catch((errors) => {
                setLoader(false);
                toast.error(errors.response.data.message);
                console.log(errors);
            });
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <>
            {loader ? <div className="loader"></div> : null}
            <Header />
            <div className="container">
                <h1 className="text-center mb-2 mt-5">{director} </h1>
                <div className="row">
                    <h4 className="my-4"></h4>
                    <div className="col-md-12">
                        <div className="float-end">
                            <select
                                className="form-select mb-3 w-100"
                                onChange={handleBedIndexChange}
                                value={selectedBedIndex}
                            >
                                {validBedIndices.map((index) => (
                                    <option key={index} value={index}>
                                        {t("floor")} {index + 1}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {selectedBedIndex !== null &&
                            beds[selectedBedIndex]?.status === 1 && (
                                <div
                                    style={{ position: "relative" }}
                                    className="text-center"
                                >
                                    <img
                                        src={
                                            beds[selectedBedIndex]?.blueprint
                                                ?.document
                                        }
                                        alt="Your Image"
                                        className="text-center w-100 mb-3"
                                        style={{
                                            cursor: "crosshair",
                                            width: 958.95,
                                            height: 400,
                                        }}
                                    />
                                    {/* Render the highlights */}
                                    {beds[selectedBedIndex]?.beds?.map(
                                        (highlight, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        left: `${highlight?.x}%`,
                                                        top: `${highlight?.y}%`,
                                                        position: "absolute",
                                                        height: "50px",
                                                        width: "50px",
                                                        margin: 0,
                                                        padding: 0,
                                                    }}
                                                >
                                                    <img
                                                        title={`${
                                                            highlight?.title
                                                        } ${t("bed")}`}
                                                        onClick={() => {
                                                            openModal();
                                                            setBed_id({
                                                                bedno: highlight.title,
                                                                bedid: highlight.id,
                                                            });
                                                        }}
                                                        src={
                                                            i18n.language ===
                                                            "en"
                                                                ? "/images/Available.png"
                                                                : "/images/Available2.png"
                                                        }
                                                        style={{
                                                            height: 50,
                                                            width: 50,
                                                            cursor: "pointer",
                                                            margin: 0,
                                                            padding: 0,
                                                        }}
                                                        className="shake-on-hover "
                                                        alt=""
                                                    />
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h5>Book Bed #{bed_id.bedno}</h5>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="">Booking Type</label>
                        <select
                            className="form-control mb-3"
                            onChange={(e) => setLife_time(e.target.value)}
                        >
                            <option selected hidden>
                                Select Booking Type....
                            </option>
                            <option value="0">Limited</option>
                            <option value="1">Life Time</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    {life_time === "0" ? (
                        <>
                            <hr />
                            <div className="col-md-6">
                                <label htmlFor="">Select Start Date</label>
                                <input
                                    type="date"
                                    className="form-control mb-3"
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">Select End Date</label>
                                <input
                                    type="date"
                                    className="form-control mb-3"
                                    onChange={(e) => setEnddate(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">Start Time</label>
                                <input
                                    type="time"
                                    className="form-control mb-3"
                                    onChange={(e) =>
                                        setstarttime(e.target.value)
                                    }
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">End Time</label>
                                <input
                                    type="time"
                                    className="form-control mb-3"
                                    onChange={(e) => setendtime(e.target.value)}
                                />
                            </div>
                            <hr />
                        </>
                    ) : null}
                    <div className="col-md-12">
                        <div className="float-end">
                            <button
                                className="btn btn-outline-primary w-100 ms-1"
                                onClick={Booknow}
                            >
                                Submit
                            </button>
                        </div>
                        <div className="float-end">
                            <button
                                className="btn btn-outline-danger w-100"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Footer />
        </>
    );
};

export default Building;
