import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CasesItem = ({ data }) => {
    const createdAt = data.created_at;
    const date = new Date(createdAt);
    const formattedTime = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });

    return (
        <div className="case-item">
            <div className="thumb">
                <img
                    src={data.document}
                    alt="Avanzando juntos"
                    width={385}
                    height={310}
                />
            </div>
            <div className="content">
                <h5 className="name">
                    <Link to="#">{data.type}</Link>
                </h5>
                <h6 className="category">{formattedTime}</h6>
            </div>
        </div>
    );
};

CasesItem.propTypes = {
    data: PropTypes.object,
};

export default CasesItem;
