import React from "react";

const Arrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="11px"
            height="18px"
            viewBox="0 0 11 18"
            version="1.1"
        >
            {/* Generator: Sketch 61 (89581) - https://sketch.com */}
            <title>icons/biking-solid</title>
            <desc>Created with Sketch.</desc>
            <g
                id="Dashboard"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="calendar"
                    transform="translate(-519.000000, -163.000000)"
                    fill="#491BFC"
                >
                    <g
                        id="Group-2"
                        transform="translate(486.000000, 124.000000)"
                    >
                        <g
                            id="icons/biking-solid"
                            transform="translate(33.000000, 39.000000)"
                        >
                            <polygon
                                id="angle-left-solid"
                                points="9.27343751 0.210937463 1.02343748 8.4609375 0.507812473 9 1.02343748 9.5390625 9.27343751 17.7890625 10.3515625 16.7109375 2.64062498 9 10.3515625 1.28906247"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default Arrow;
