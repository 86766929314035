import React from "react";
import { Link } from "react-router-dom";
import "./LoginPage.css";
import { useState } from "react";
import { axiosInstance } from "../config/https";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
// import Image from "/img/nurse.png";
const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const history = useHistory();

    const Login = () => {
        setLoader(true);
        const formdata = new FormData();
        formdata.append("email", email);
        formdata.append("password", password);
        formdata.append("type", "user");
        axiosInstance
            .post(`login`, formdata)
            .then((e) => {
                console.log(e);
                if (e.data.status === true) {
                    toast.success("Login Successfully");
                    setLoader(false);
                    localStorage.setItem(
                        "accessToken",
                        e.data.data.access_token
                    );
                    localStorage.setItem(
                        "userDetails",
                        JSON.stringify(e.data.data)
                    );
                    const currentLocation = history.location.pathname;
                    console.log("Previous Path:", currentLocation);
                    if (currentLocation === "/signup") {
                        history.push("/");
                    } else {
                        history.goBack();
                    }
                } else {
                    toast.error(e.data.error);
                    setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
                console.log(error, "error");
            });
    };

    const [showPass, setShowPass] = useState(false);
    return (
        <div
            style={{
                backgroundImage: "url(/img/bg-login2.png)",
                height: "100vh",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
            }}
        >
            <div className="container">
                <div className="row ">
                    <Link
                        to="/"
                        className="login_heading text-white text-center"
                    >
                        AVANZANDO JUNTOS
                        <br />
                        CORPORACIÓN
                    </Link>
                    <div className="login_subheading text-white text-center">
                        El proceso es inevitable, el cuidado indispensable
                    </div>
                    <div className="offset-md-4 col-md-4">
                        <div className="row ">
                            <div className="col-md-12 ">
                                <div className="container">
                                    <label className="text-white">Email</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">
                                            <i className="icofont-ui-user" />
                                        </span>
                                        <input
                                            type="email"
                                            className="form-control"
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                        />
                                    </div>
                                    <label className="text-white">
                                        Password
                                    </label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">
                                            <i className="icofont-ui-lock" />
                                        </span>
                                        <input
                                            type={
                                                showPass ? "text" : "password"
                                            }
                                            className="form-control"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                        <span className="input-group-text">
                                            <i
                                                className="icofont-eye-alt"
                                                onClick={() =>
                                                    setShowPass(!showPass)
                                                }
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="offset-md-1 col-md-10 text-center">
                                    <p className="text-white">
                                        {t(`you_dont_have_an_account?`)}
                                        <Link to={"/signup"}>
                                            <i>
                                                <u>{t("sign_up")}</u>
                                            </i>
                                        </Link>{" "}
                                    </p>
                                    <button
                                        className="btn btn-primary w-75"
                                        onClick={Login}
                                    >
                                        Login{" "}
                                        {loader ? (
                                            <i className="icofont-spinner icofont-spin fs-3" />
                                        ) : null}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
