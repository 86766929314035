import React, { useEffect, useState } from "react";
import "./Chat.css";
import Arrow from "../../Arrow.jsx";
import Pusher from "pusher-js";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/https";
import Header from "../../layouts/header";
const ProChat = () => {
    let UserDetails = localStorage.getItem("userDetails");
    UserDetails = JSON.parse(UserDetails);
    const [Loader, setLoader] = useState(false);
    const [isSend, setIssend] = useState(false);
    const { id } = useParams();
    const [isname, setIsname] = useState({ name: "", email: "", img: "" });
    const [sideusers, setSideUsers] = useState([]);
    const [showChat, sethidechat] = useState(false);
    const [message, setMessage] = useState("");
    const [showmessage, setShowmessage] = useState([]);
    const [con_Id, setCon_ID] = useState("");
    const [sending, setsending] = useState(false);
    const chattoglle = () => {
        sethidechat(true);
    };
    const getMessage = (id) => {
        setLoader(true);
        axiosInstance
            .get(
                `user/professional_conversations/${
                    id !== undefined ? id : con_Id
                }`
            )
            .then((response) => {
                console.log(response, "Inbox message");
                if (response.data.status === true) {
                    setShowmessage(response.data.data);
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: "smooth",
                    });
                    var element = document.querySelector(
                        ".modal-dialog-scrollable"
                    );
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: "smooth",
                    });
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((errors) => setLoader(false));
    };

    const Inbox = () => {
        setLoader(true);
        axiosInstance
            .get("user/professional_conversations")
            .then((response) => {
                console.log(response.data, "sider");
                if (response.data.status === true) {
                    setSideUsers(response?.data?.data);
                    setLoader(false);
                } else {
                    setLoader(false);
                    toast.error(response.data.error);
                }
            })
            .catch((errors) => {
                setLoader(false);
            });
    };
    const StoreParticipants = () => {
        setLoader(true);
        const formdata = new FormData();
        formdata.append("professional_id", id);
        formdata.append("user_id", UserDetails?.id);

        axiosInstance
            .post("user/professional_conversation_participants", formdata)
            .then((response) => {
                console.log(response);
                Inbox();
                setLoader(false);
            })
            .catch((errors) => {
                setLoader(false);
                console.log(errors);
            });
    };
    const Sendmessage = () => {
        setsending(true);

        const formdata = new FormData();
        formdata.append("participant_id", con_Id);
        formdata.append("sender_type", "user");
        formdata.append("body", message);

        axiosInstance
            .post("user/professional_conversations", formdata)
            .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    getMessage();
                    setMessage("");
                    setsending(false);
                } else {
                    setsending(false);

                    toast.error("Unknown error occur");
                }
            })
            .catch((errors) => {
                console.log(errors);
                setsending(false);
            });
    };
    const UpdateStatus = (id) => {
        setLoader(true);
        axiosInstance
            .post(
                `user/professional_conversations/update/${
                    id !== undefined ? id : con_Id
                }/professional`
            )
            .then((response) => {
                console.log(response);
                setLoader(false);
                Inbox();
            })
            .catch((errors) => {
                setLoader(false);
                console.log(errors);
            });
    };
    useEffect(() => {
        Inbox();
        StoreParticipants();
        if (isSend === true) {
            getMessage();
            setTimeout(() => {
                setIssend(false);
            }, 2000);
        }
    }, [isSend]);
    console.log(isSend, "");
    return (
        <>
            {Loader ? <div className="loader"></div> : null}

            <Header sendingCheck={setIssend} />
            <section className="message-area">
                <div className="container-fuild">
                    <div className="row" style={{ height: "auto" }}>
                        <div className="col-12">
                            <div className="chat-area">
                                {/* <!-- chatlist --> */}
                                <div className="chatlist">
                                    <div className="modal-dialog-scrollable">
                                        <div className="modal-content">
                                            <div className="chat-header">
                                                <div className="msg-search">
                                                    <input
                                                        type="search"
                                                        className="form-control w-100"
                                                        id="inlineFormInputGroup"
                                                        placeholder="Search"
                                                        aria-label="search"
                                                    />
                                                </div>
                                                <ul
                                                    className="nav nav-tabs"
                                                    id="myTab"
                                                    role="tablist"
                                                >
                                                    <li
                                                        className="nav-item"
                                                        role="presentation"
                                                    >
                                                        <Link
                                                            to={
                                                                "/professionalchat"
                                                            }
                                                        >
                                                            <button
                                                                className={`nav-link ${
                                                                    window
                                                                        .location
                                                                        .pathname ===
                                                                        "/professionalchat" ||
                                                                    window
                                                                        .location
                                                                        .pathname ===
                                                                        "/professionalchat/:id"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                            >
                                                                Professional
                                                            </button>
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className="nav-item"
                                                        role="presentation"
                                                    >
                                                        <Link to={"/Chat"}>
                                                            <button
                                                                className={`nav-link ${
                                                                    window
                                                                        .location
                                                                        .pathname ===
                                                                        "/Chat" ||
                                                                    window
                                                                        .location
                                                                        .pathname ===
                                                                        "/Chat/:id"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                            >
                                                                Care Home
                                                            </button>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="modal-body">
                                                {/* <!-- chat-list --> */}
                                                <div className="chat-lists pt-2">
                                                    <div
                                                        className="tab-content"
                                                        id="myTabContent"
                                                    >
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="Open"
                                                            role="tabpanel"
                                                            aria-labelledby="Open-tab"
                                                        >
                                                            {/* <!-- chat-list --> */}
                                                            <div className="chat-list">
                                                                {sideusers?.map(
                                                                    (e) => {
                                                                        return (
                                                                            <>
                                                                                <Link
                                                                                    to={`/professionalchat/${e.professional_id}`}
                                                                                    className="d-flex align-items-center "
                                                                                    onClick={() => {
                                                                                        setShowmessage(
                                                                                            []
                                                                                        );

                                                                                        setIsname(
                                                                                            {
                                                                                                name: e
                                                                                                    .professional
                                                                                                    .fname,
                                                                                                email: e
                                                                                                    .professional
                                                                                                    .email,
                                                                                                img: e
                                                                                                    .professional
                                                                                                    .image,
                                                                                            }
                                                                                        );
                                                                                        chattoglle();

                                                                                        localStorage.setItem(
                                                                                            "particapati_id",
                                                                                            e.professional_id
                                                                                        );
                                                                                        getMessage(
                                                                                            e
                                                                                                .conversations[0]
                                                                                                .participant_id
                                                                                        );
                                                                                        setCon_ID(
                                                                                            e
                                                                                                .conversations[0]
                                                                                                .participant_id
                                                                                        );
                                                                                        UpdateStatus(
                                                                                            e
                                                                                                .conversations[0]
                                                                                                .participant_id
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <div className="flex-shrink-0">
                                                                                        <img
                                                                                            className="img-fluid"
                                                                                            src={
                                                                                                e
                                                                                                    .professional
                                                                                                    .image
                                                                                            }
                                                                                            alt="userimg"
                                                                                            height={
                                                                                                45
                                                                                            }
                                                                                            width={
                                                                                                45
                                                                                            }
                                                                                        />
                                                                                        <span className="active"></span>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h3>
                                                                                            {
                                                                                                e
                                                                                                    .professional
                                                                                                    .fname
                                                                                            }
                                                                                            {e.conversations.map(
                                                                                                (
                                                                                                    x,
                                                                                                    index
                                                                                                ) =>
                                                                                                    x.sender_type ===
                                                                                                        "professional" &&
                                                                                                    index ===
                                                                                                        e
                                                                                                            .conversations
                                                                                                            .length -
                                                                                                            1 &&
                                                                                                    x.status ===
                                                                                                        0 ? (
                                                                                                        <span className="badge bg-secondary">
                                                                                                            New
                                                                                                        </span>
                                                                                                    ) : null
                                                                                            )}
                                                                                        </h3>
                                                                                        <p
                                                                                            className="d-inline-block text-truncate"
                                                                                            title={
                                                                                                e
                                                                                                    .professional
                                                                                                    .email
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                e
                                                                                                    .professional
                                                                                                    .email
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </Link>
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                            {/* <!-- chat-list --> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- chatlist --> */}

                                {/* <!-- chatbox --> */}
                                {showChat ? (
                                    <div
                                        className={
                                            showChat
                                                ? " chatbox showbox"
                                                : "showbox"
                                        }
                                    >
                                        <div class="modal-dialog-scrollable">
                                            <div
                                                class="modal-content"
                                                style={{ height: "70vh" }}
                                            >
                                                <div class="msg-head">
                                                    <div class="row">
                                                        <div class="col-8">
                                                            <div class="d-flex align-items-center">
                                                                <span
                                                                    className="chat-icon"
                                                                    onClick={() =>
                                                                        sethidechat(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    <Arrow />
                                                                </span>
                                                                <div className="flex-shrink-0">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src={
                                                                            isname.img
                                                                        }
                                                                        alt="user img"
                                                                        height={
                                                                            45
                                                                        }
                                                                        width={
                                                                            45
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h3>
                                                                        {
                                                                            isname.name
                                                                        }
                                                                    </h3>
                                                                    <p>
                                                                        {
                                                                            isname.email
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal-body">
                                                    <div className="msg-body px-3">
                                                        <ul>
                                                            {showmessage.map(
                                                                (e) => {
                                                                    const createdAt =
                                                                        e.created_at;
                                                                    const date =
                                                                        new Date(
                                                                            createdAt
                                                                        );
                                                                    const formattedTime =
                                                                        date.toLocaleString(
                                                                            "en-US",
                                                                            {
                                                                                hour: "numeric",
                                                                                minute: "numeric",
                                                                                hour12: true,
                                                                            }
                                                                        );

                                                                    console.log(
                                                                        formattedTime
                                                                    );

                                                                    return (
                                                                        <>
                                                                            {e.sender_type ===
                                                                            "user" ? (
                                                                                <li className="repaly">
                                                                                    <p>
                                                                                        {" "}
                                                                                        {
                                                                                            e.body
                                                                                        }
                                                                                    </p>
                                                                                    <span className="time">
                                                                                        {
                                                                                            formattedTime
                                                                                        }
                                                                                    </span>
                                                                                </li>
                                                                            ) : (
                                                                                <li className="sender">
                                                                                    <p>
                                                                                        {" "}
                                                                                        {
                                                                                            e.body
                                                                                        }
                                                                                    </p>
                                                                                    <span className="time">
                                                                                        {
                                                                                            formattedTime
                                                                                        }
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="send-box">
                                                    <form action="">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            aria-label="message…"
                                                            value={message}
                                                            onChange={(e) =>
                                                                setMessage(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            placeholder="Write message…"
                                                        />

                                                        <button
                                                            disabled={sending}
                                                            onClick={
                                                                Sendmessage
                                                            }
                                                        >
                                                            <i
                                                                className="fa fa-paper-plane"
                                                                aria-hidden="true"
                                                            ></i>{" "}
                                                            Send{" "}
                                                            {sending ? (
                                                                <i className="icofont-spinner icofont-spin fs-3"></i>
                                                            ) : (
                                                                <i className="icofont-paper-plane fs-3"></i>
                                                            )}
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer/> */}
        </>
    );
};

export default ProChat;
