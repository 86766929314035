import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Logo = ({ image, classOption, height, widht }) => {
    return (
        <Link className={`${classOption}`} to={process.env.PUBLIC_URL + "/"}>
            <img
                style={{ width: widht, height: height }}
                className="sticky-img "
                src={process.env.PUBLIC_URL + image}
                alt="Logo"
            />
        </Link>
    );
};

Logo.propTypes = {
    image: PropTypes.string,
    classOption: PropTypes.string,
};

Logo.defaultProps = {
    classOption: "text-center",
};

export default Logo;
