import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const AdsDetailsWrap = ({ data }) => {
    // Assuming data is your object and contains the "updated_at" field.
    console.log(data, "data");

    let dateFormatted = "N/A"; // Default value for the formatted date
    let timeFormatted = "N/A"; // Default value for the formatted time

    if (data && data.updated_at) {
        const updated_at = data.updated_at;

        // Convert to Date object
        const dateObj = new Date(updated_at);

        // Format date (DD MMMM YY)
        const options = {
            day: "2-digit",
            month: "long",
            year: "2-digit",
        };
        const dateFormatter = new Intl.DateTimeFormat("en-US", options);
        dateFormatted = dateFormatter.format(dateObj);

        // Time format 12-hour (hh:mm AM/PM)
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        timeFormatted = `${hours12}:${minutes
            .toString()
            .padStart(2, "0")} ${ampm}`;
    }

    console.log("Formatted Date:", dateFormatted);
    console.log("Time Format 12-hour (hh:mm AM/PM):", timeFormatted);

    return (
        <div className="blog-details-wrpa">
            <div className="content">
                <h2 className="title">{data?.title}</h2>
                <img
                    src={data?.image}
                    alt="Avanzando juntos-Blog"
                    style={{ width: 756, height: 503.95 }}
                />
                <div className="meta">
                    {/* {cate} */}
                    <Link className="author" to={" #"}>
                        {dateFormatted} {timeFormatted}
                    </Link>
                    <span>by</span>
                    <a href={data.link} target="_blank" className="author">
                        {data?.business?.title}
                    </a>
                </div>

                <div
                    className="desc"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                />

                {/* <SidebarTag data={BlogData} /> */}
            </div>
        </div>
    );
};

AdsDetailsWrap.propTypes = {
    data: PropTypes.object,
};

export default AdsDetailsWrap;
