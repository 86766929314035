import React, { useEffect, useState } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Breadcrumb from "../containers/global/breadcrumb/index.jsx";
import ServiceDetailsContainer from "../containers/service-details";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index.jsx";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { axiosInstance } from "../config/https";

const ServiceDetails = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({});
    const { id } = useParams();
    const Details = () => {
        setLoader(true);
        axiosInstance
            .get(`carehomes/${id}`)
            .then((response) => {
                console.table(response);
                const Data = response.data.data;
                if (response.data.status === true) {
                    setData(Data);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((errors) => {
                console.table(errors);
                setLoader(false);
            });
    };
    useEffect(() => {
        Details();
    }, []);
    return (
        <React.Fragment>
            {loader ? <div className="loader"></div> : null}
            <Layout>
                <SEO title="Avanzando juntos – Service Details" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <Breadcrumb
                            prevs={[
                                { text: "Home", path: "/" },
                                { text: "Service", path: "/service" },
                            ]}
                            contentThree={data?.establishment}
                        />
                        <ServiceDetailsContainer data={data} />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ServiceDetails;
