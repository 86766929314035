import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    const { t } = useTranslation();
    return (
        <div className="contact-info-content mb-2">
            <div className="info-address">
                <h2 className="title">
                    Hatillo,<span>Puerto Rico</span>
                </h2>
                <p>Calle padre delgado #77, Hatillo PR</p>
                <a href="mailto://avanzandojuntos.pr@gmail.com">
                    <span>Email: </span>
                    avanzandojuntos.pr@gmail.com
                </a>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>{t(`call_directly`).replace(/_/g, " ")}:</h6>
                    <p>939-246-0206</p>
                </div>
                <div className="info-tem">
                    <h6>{t(`brand_offices`).replace(/_/g, " ")}:</h6>
                    <p>Calle padre delgado #77, Hatillo PR</p>
                </div>
                <div className="info-tem mb-0">
                    <h6>{t(`work_hours`).replace(/_/g, " ")}:</h6>
                    <p>Mon - Fri: 8AM - 4PM</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
