import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import ProSidebarWorkingTime from "../../components/sidebar/sidebar-working-time/Proindex.jsx";

import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CasesItem from "../../components/cases/index.jsx";

SwiperCore.use([Pagination]);
const ProServiceDetailsContainer = ({ data }) => {
    const swiperOption = {
        slidesPerView: 2,
        speed: 1000,
        loop: true,
        spaceBetween: 30,
        autoplay: false,
        pagination: { clickable: true },
        breakpoints: {
            1200: {
                slidesPerView: 2,
            },

            991: {
                slidesPerView: 2,
            },

            767: {
                slidesPerView: 2,
            },

            576: {
                slidesPerView: 2,
            },

            0: {
                slidesPerView: 1,
            },
        },
    };
    return (
        <section className="department-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="department-wrpp">
                            <div className="sidebar-wrapper ">
                                {/* <DepartmentWidget /> */}
                                <div className="widget-item">
                                    <div className="widget-work-hours card shadow bg-light">
                                        <div>
                                            <div className="thumb text-center">
                                                <img
                                                    src={data.image}
                                                    alt="Avanzando juntos"
                                                    width={200}
                                                    height={200}
                                                    className="mb-3 "
                                                    style={{
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </div>
                                            <div className="membr-info">
                                                <h5 className="name capitalizing text-center">
                                                    {data.fname}&nbsp;
                                                    {data.lname}{" "}
                                                    <span className="fs-5">
                                                        ({data.type})
                                                    </span>
                                                </h5>
                                                <h6 className="designation text-center mb-3">
                                                    {data.email}
                                                </h6>
                                                <div className="text-center">
                                                    <Link
                                                        to={`/professionalchat/${data.id}`}
                                                    >
                                                        <button className="btn btn-outline-primary w-100 p-2  btn-lg">
                                                            Message
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ProSidebarWorkingTime data={data} />
                                {/* <Appointment /> */}
                            </div>

                            <div className="department-content card shadow bg-light p-3 mt-3">
                                {/* <ServiceDetails data={data} /> */}
                                <div className="membr-info ">
                                    <h2 className="title">
                                        <span>Care Home </span> Details
                                    </h2>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Ability</label>
                                            <input
                                                className="form-control mb-3"
                                                readOnly
                                                value={data?.carehome?.ability}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="capitalizing">
                                                License
                                            </label>
                                            <input
                                                className="form-control mb-3"
                                                readOnly
                                                value={data?.carehome?.license}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="capitalizing">
                                                license status
                                            </label>
                                            <input
                                                className="form-control mb-3"
                                                readOnly
                                                value={
                                                    data?.carehome
                                                        ?.license_status
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="capitalizing">
                                                email
                                            </label>
                                            <input
                                                className="form-control mb-3"
                                                readOnly
                                                value={data?.carehome?.email}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="capitalizing">
                                                address
                                            </label>
                                            <input
                                                className="form-control mb-3"
                                                readOnly
                                                value={data?.carehome?.address}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="capitalizing">
                                                telephone
                                            </label>
                                            <input
                                                className="form-control mb-3"
                                                readOnly
                                                value={
                                                    data?.carehome?.telephone
                                                }
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="capitalizing">
                                                town
                                            </label>
                                            <input
                                                className="form-control mb-3"
                                                readOnly
                                                value={data?.carehome?.town}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="capitalizing">
                                                pricing
                                            </label>
                                            <input
                                                className="form-control mb-3"
                                                readOnly
                                                value={data?.carehome?.pricing}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="capitalizing">
                                                description
                                            </label>
                                            <textarea
                                                className="form-control mb-3"
                                                readOnly
                                                value={
                                                    data?.carehome?.description
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="faq-area">
                                    <h2 className="title">
                                        <span>General</span> FAQs
                                    </h2>
                                    <div className="accordian-content">
                                        <AccordionWrapTwo />
                                    </div>
                                </div> */}

                                <div className="case-area">
                                    <h2 className="title">
                                        <span>Documents</span>
                                    </h2>
                                    <Swiper
                                        className="case-slider-container"
                                        {...swiperOption}
                                    >
                                        {data?.professional_media &&
                                            data?.professional_media.map(
                                                (single, key) => {
                                                    return (
                                                        <SwiperSlide key={key}>
                                                            <CasesItem
                                                                key={key}
                                                                data={single}
                                                            />
                                                        </SwiperSlide>
                                                    );
                                                }
                                            )}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ProServiceDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default ProServiceDetailsContainer;
