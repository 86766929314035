import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config/https";

const PricingItem = ({ data, coupon }) => {
    let userDetails = localStorage?.getItem("userDetails");
    userDetails = JSON?.parse(userDetails);
    const buyPackages = (id) => {
        const formdata = new FormData();
        formdata.append("type", "user");
        formdata.append("coupon", coupon);
        formdata.append("plan_id", id);
        formdata.append("user_id", userDetails.id);
        axiosInstance
            .post("user/subscriptions/user", formdata)
            .then((e) => {
                if (e.data.status == true) {
                    window.location.href = e.data.data;
                }
            })
            .catch((e) => console.log(e));
    };
    return (
        <>
            <div className={"pricing-item item-one"}>
                <div className="pricing-title">
                    <h5>{data.type}</h5>
                </div>
                <div className="pricing-amount">
                    <h2>
                        <sup>$</sup>
                        {data.price}
                    </h2>
                    <span class="period">
                        / {data.duration}/{data.duration_type}
                    </span>
                </div>
                <div className="pricing-content">
                    <p className="desc">{data.title}</p>
                    {/* <ul className="pricing-list">
                    {data.pricingList &&
                        data.pricingList.map((single, key) => {
                            return (
                                <li key={key}>
                                    <i className="icofont-check-circled"></i>
                                    {single}
                                </li>
                            );
                        })}
                </ul> */}
                </div>
                <div className="pricing-footer">
                    <Link
                        className={"btn-theme btn-border"}
                        to="#"
                        onClick={() => buyPackages(data.id)}
                    >
                        Get Started Now
                    </Link>
                </div>
            </div>
        </>
    );
};

PricingItem.propTypes = {
    data: PropTypes.object,
};

export default PricingItem;
