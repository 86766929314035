import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer
            className="footer-area p-3"
            style={{ backgroundColor: "#1068d2" }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Logo
                                    widht={"50%"}
                                    height={186}
                                    image={`${process.env.PUBLIC_URL}/img/logo1.png`}
                                />
                                <p className="mb-0 text-white">
                                    {t(
                                        `a_services_and_communication_platform.`
                                    ).replace(/_/g, " ")}
                                </p>
                                <ul className="widget-contact-info">
                                    <li className="info-address text-white">
                                        <i className="icofont-location-pin text-white"></i>
                                        Calle padre delgado #77, Hatillo PR
                                    </li>
                                    <li className="info-mail text-white">
                                        <i className="icofont-email text-white"></i>
                                        <a
                                            href="mailto://avanzandojuntos.pr@gmail.com"
                                            className="text-white"
                                        >
                                            avanzandojuntos.pr@gmail.com
                                        </a>
                                    </li>
                                    <li className="info-phone text-white">
                                        <i className="icofont-ui-call text-white"></i>
                                        <a
                                            href="tel://939-246-0206 "
                                            className="text-white"
                                        >
                                            939-246-0206{" "}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3 d-lg-none d-xl-block">
                        <div className="widget-item d-inline-block">
                            <h4 className="widget-title line-bottom">
                                Popular Tags
                            </h4>
                            <div className="widget-tags">
                                <ul>
                                    <li>
                                        <Link to="/">Amazing</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Envato</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Themes</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Clean</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Wordpress</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Creative</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Mutilpurpose</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Retina Ready</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Twitter</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Responsive</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom">
                                Recent Posts
                            </h4>
                            <nav className="widget-posts">
                                <ul className="posts-item">
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Lorem Ispum dolor sit amet putilor
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Medical is all about quality.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Is your website user friendly ?
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Ai offer weekly updates &amp; more.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Customer should love your web.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Your site smooth and stunning.
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div> */}
                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-6 offset-lg-0 col-xl-6">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom text-white">
                                {t("newsletter").replace(/_/g, " ")}
                            </h4>
                            <div className="widget-newsletter text-white">
                                <p>
                                    {t(
                                        `sign_up_for_our_mailing_list_to_get_latest_updates_and_offers.`
                                    ).replace(/_/g, " ")}
                                </p>
                                <Newsletter mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
                            </div>
                            <div className="widget-social-icons">
                                {/* <SocialIcon
                                    path="https://twitter.com/"
                                    icon="icofont-twitter"
                                />
                                <SocialIcon
                                    path="https://myaccount.google.com/"
                                    icon="icofont-google-plus"
                                />
                                <SocialIcon
                                    path="https://www.pinterest.com/"
                                    icon="icofont-pinterest"
                                />
                                <SocialIcon
                                    path="https://rss.com/"
                                    icon="icofont-rss"
                                /> */}
                                <SocialIcon
                                    path="https://www.facebook.com/profile.php?id=100087430625348"
                                    icon="icofont-facebook"
                                />
                                <SocialIcon
                                    path="https://instagram.com/avanzandojuntospr?igshid=MzRlODBiNWFlZA=="
                                    icon="icofont-instagram"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom p-2 mt-5">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <div className="widget-copyright">
                                <p>
                                    &copy; 2021{" "}
                                    <span className="text-uppercase text-dark">
                                        Avanzando juntos
                                    </span>
                                    . Made with by{" "}
                                    <a
                                        target="_blank"
                                        href="https://hnhtechsolutions.com/"
                                        rel="noreferrer"
                                    >
                                        HnHSoftTechSolutions
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
