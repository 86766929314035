import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { axiosInstance } from "../config/https";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
SwiperCore.use([Navigation]);

const Dummypage = () => {
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const { id } = useParams();
    const Dummy = () => {
        setLoader(true);
        axiosInstance
            .get(`advertisements/${id}`)
            .then((response) => {
                console.log(response);
                if (response.data?.status === true) {
                    setLoader(false);
                    setData(response?.data?.data);
                } else {
                    setLoader(false);
                }
            })
            .catch((errors) => {
                setLoader(false);

                console.log(errors);
            });
    };
    useEffect(() => {
        Dummy();
    }, []);
    const swiperOption = {
        loop: true,
        speed: 600,
        spaceBetween: 0,
        slidesPerView: 1,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".hero-slider .swiper-button-next",
            prevEl: ".hero-slider .swiper-button-prev",
        },
    };
    return (
        <>
            {loader ? <div className="loader"></div> : null}
            <Header />
            <div className="hero-slider-area">
                <img src={data?.image} width={1920} height={778} alt="" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="department-wrpp">
                                <div className="department-content card shadow bg-light p-3 mt-3">
                                    {/* <ServiceDetails data={data} /> */}
                                    <div className="membr-info ">
                                        <h2 className="title">
                                            <a
                                                href={data?.link}
                                                target="_blank"
                                            >
                                                {data?.title}
                                            </a>
                                        </h2>
                                        <h5
                                            dangerouslySetInnerHTML={{
                                                __html: data?.description,
                                            }}
                                        ></h5>
                                    </div>
                                </div>
                                <div className="sidebar-wrapper ">
                                    {/* <DepartmentWidget /> */}
                                    <div className="widget-item">
                                        <div className="widget-work-hours card shadow bg-light">
                                            <div>
                                                <div className="thumb text-center">
                                                    <img
                                                        src={
                                                            data?.business
                                                                ?.image
                                                        }
                                                        alt="Avanzando juntos"
                                                        width={200}
                                                        height={200}
                                                        className="mb-3 "
                                                        style={{
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </div>
                                                <div className="membr-info">
                                                    <h5 className="name capitalizing text-center">
                                                        {data?.business?.title}
                                                    </h5>
                                                    <h6 className="designation text-center mb-3">
                                                        {data?.business?.email}
                                                    </h6>
                                                    <div className="text-center">
                                                        <a
                                                            href={data?.link}
                                                            target="_blank"
                                                        >
                                                            <button className="btn btn-outline-primary w-100 p-2  btn-lg">
                                                                View
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Dummypage;
