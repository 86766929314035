import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const BlogListItem = ({ data }) => {
    let dateFormatted = "N/A"; // Default value for the formatted date
    let timeFormatted = "N/A"; // Default value for the formatted time

    if (data && data?.updated_at) {
        const updated_at = data?.updated_at;

        // Convert to Date object
        const dateObj = new Date(updated_at);

        // Format date (DD MMMM YY)
        const options = {
            day: "2-digit",
            month: "long",
            year: "2-digit",
        };
        const dateFormatter = new Intl.DateTimeFormat("en-UK", options);
        dateFormatted = dateFormatter.format(dateObj);

        // Time format 12-hour (hh:mm AM/PM)
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        timeFormatted = `${hours12}:${minutes
            .toString()
            .padStart(2, "0")} ${ampm}`;
    }

    console.log("Formatted Date:", dateFormatted);
    console.log("Time Format 12-hour (hh:mm AM/PM):", timeFormatted);
    return (
        <div className="post-item">
            <div className="thumb">
                <Link to={process.env.PUBLIC_URL + `/blog-details/${data?.id}`}>
                    {data.type === "video" ? (
                        <video
                            src={data?.image}
                            autoPlay
                            muted
                            loop
                            alt="Avanzando juntos-Blog"
                            style={{ width: 300, height: 193 }}
                        />
                    ) : data.type === "article" ? (
                        <iframe
                            src={data?.image}
                            // style={{ width: 300, height: 193 }}
                        />
                    ) : (
                        <img
                            src={data?.image}
                            alt="Avanzando juntos-Blog"
                            style={{ width: 300, height: 193 }}
                        />
                    )}
                </Link>
            </div>
            <div className="content">
                <Link className="category" to={"#"}>
                    {data.type}
                </Link>{" "}
                <h4 className="title">
                    <Link
                        to={
                            process.env.PUBLIC_URL + `/blog-details/${data?.id}`
                        }
                    >
                        {data?.title}
                    </Link>
                </h4>
                <div className="meta">
                    <Link to={"#"}>
                        {dateFormatted} {timeFormatted}
                    </Link>
                    <span>by</span>
                    <Link className="author" to={"#"}>
                        {data?.business_id === null
                            ? "Admin"
                            : data?.business?.title}
                    </Link>
                </div>
            </div>
        </div>
    );
};

BlogListItem.propTypes = {
    data: PropTypes.object,
};

export default BlogListItem;
