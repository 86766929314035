import SectionTitle from "../../../components/section-title";
import AppointmentForm from "../../../components/appointment-form";
import { useTranslation } from "react-i18next";
import ContactForm from "../../../components/contact-form";

const AppointmentContainer = () => {
    const { t } = useTranslation();
    return (
        <section className="appointment-area bg-gray">
            <div className="appointment-form-style1">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="appointment-form capitalizing">
                                <SectionTitle
                                    subTitle={t("need_help?").replace(
                                        /_/g,
                                        " "
                                    )}
                                    title={`<span>${t("book_an").replace(
                                        /_/g,
                                        " "
                                    )}</span> ${t("appointment")}`}
                                />
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AppointmentContainer;
