import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css"; // Import Swiper styles
import Rating from "react-rating";
SwiperCore.use([Navigation, Autoplay]);

const ReviewsIndex = ({ data }) => {
    const swiperOption = {
        // loop: true,
        speed: 600,
        spaceBetween: 0,
        slidesPerView: 1,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".hero-slider .swiper-button-next",
            prevEl: ".hero-slider .swiper-button-prev",
        },
    };

    

    return (
        <section className="spacer">
            <Swiper effect="fade" className="hero-slider" {...swiperOption}>
                {data.length > 0 &&
                    data.map((review, key) => (
                        <SwiperSlide key={key}>
                            <div className="quote">
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                    {/* <img
                                        className="quote-icon my-3 "
                                        src={review?.user?.image}
                                        alt=""
                                        style={{
                                            width: 100,
                                            height: 100,
                                            borderRadius: "50%",
                                        }}
                                    /> */}
                                    <Rating
                                        initialRating={review?.rating}
                                        readonly
                                        fullSymbol={"icofont-star text-warning fs-4"} 
                                        stop={review?.rating}

                                    />{" "}
                                    <div className="name ">{`-${review?.fname} ${review?.lname}-`}</div>
                                    <div className="designation ">
                                        {review?.review}
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </section>
    );
};

export default ReviewsIndex;
