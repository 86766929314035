import React from "react";
import { Link } from "react-router-dom";
import BlogListItem from "../../../components/blog-list";
import { useEffect, useState } from "react";
import Google_Ads from "../../../components/google-ads";
import { axiosInstance } from "../../../config/https";
import { useTranslation } from "react-i18next";

const BlogListContainer = () => {
    const [loader, setLoader] = useState(false);
    const [BlogData, setBlogData] = useState([]);
    const [pagination, setPagination] = useState([]);
    const { t } = useTranslation();

    const Blogs = (type) => {
        setLoader(true);
        axiosInstance
            .get(`${type === undefined ? `posts` : `posts?type=${type}`}`)
            .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setBlogData(response?.data?.data?.data);
                    setPagination(response?.data?.data?.links);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((errors) => {
                setLoader(false);
                console.error(errors);
            });
    };
    const pagiBlogs = (url) => {
        setLoader(true);
        axiosInstance
            .get(`posts?${url}`)
            .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setBlogData(response?.data?.data?.data);
                    setPagination(response?.data?.data?.links);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((errors) => {
                setLoader(false);
                console.error(errors);
            });
    };
    useEffect(() => {
        Blogs();
    }, []);
    return (
        <>
            {loader ? <div className="loader"></div> : null}
            <div className="blog-area section-py blog-border-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="d-flex bg-light mb-3">
                                <button
                                    className="btn btn-primary capitalizing"
                                    onClick={() => Blogs("articles")}
                                >
                                    {t("articles")}
                                </button>{" "}
                                &nbsp;
                                <button
                                    className="btn btn-primary capitalizing"
                                    onClick={() => Blogs("video")}
                                >
                                    {t("videos")}
                                </button>{" "}
                                &nbsp;
                                <button
                                    className="btn btn-primary capitalizing"
                                    onClick={() => Blogs("photo")}
                                >
                                    {t("photos")}
                                </button>
                            </div>
                            <div className="post-items-style2">
                                {BlogData.length === 0 ? (
                                    <p>{t(`no_data_found`)}</p>
                                ) : (
                                    BlogData.map((single, i) => {
                                        return (
                                            <BlogListItem
                                                key={i}
                                                data={single}
                                            />
                                        );
                                    })
                                )}
                            </div>
                            <div className="pagination-area p-2 mb-md-80">
                                <nav>
                                    <ul className="page-numbers">
                                        {BlogData.length === 0
                                            ? null
                                            : pagination
                                                  .filter((s) => s.url !== null)
                                                  .map((e) => {
                                                      const originalUrl = e.url;
                                                      const pageValue =
                                                          originalUrl.match(
                                                              /page=\d+/
                                                          )[0];
                                                      console.log(
                                                          pageValue,
                                                          "meow"
                                                      );

                                                      return (
                                                          <>
                                                              <li>
                                                                  <Link
                                                                      to={"#"}
                                                                      onClick={() =>
                                                                          pagiBlogs(
                                                                              pageValue
                                                                          )
                                                                      }
                                                                      className={`page-number ${
                                                                          e.active ===
                                                                          true
                                                                              ? "current"
                                                                              : ""
                                                                      }`}
                                                                  >
                                                                      {e.label ===
                                                                      "pagination.previous" ? (
                                                                          <i class="icofont-simple-left"></i>
                                                                      ) : e.label ===
                                                                        "pagination.next" ? (
                                                                          <i class="icofont-simple-right"></i>
                                                                      ) : (
                                                                          e.label
                                                                      )}
                                                                  </Link>
                                                              </li>
                                                          </>
                                                      );
                                                  })}

                                        {/* <li>
                                            <Link
                                                className="page-number"
                                                to="/"
                                            >
                                                2
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="page-number"
                                                to="/"
                                            >
                                                3
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="page-number"
                                                to="/"
                                            >
                                                4
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="page-number next"
                                                to="/"
                                            >
                                                <i className="icofont-thin-right"></i>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-wrapper blog-sidebar-wrapper">
                                <Google_Ads height={700} />
                                {/* <div className="widget-item">
                                    <SidebarTitle title="Search" />
                                    <SidebarSearch />
                                </div> */}
                                {/* <div className="widget-item">
                                    <SidebarTitle title="Categories" />
                                    <SidebarCategories data={BlogData} />
                                </div> */}
                                {/* <div className="widget-item">
                                    <SidebarTitle title="Recent posts" />
                                    <SidebarPost data={BlogData} />
                                </div> */}
                                {/* <div className="widget-item">
                                    <SidebarTitle title="TAGS" />
                                    <SidebarTag data={BlogData} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogListContainer;
