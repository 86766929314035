import React, { useState } from "react";
import Comment from "../../components/comment";
import SectionTitle from "../../components/section-title";
import BlogData from "../../data/blog.json";
import Rating from "react-rating";
import { axiosInstance } from "../../config/https";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CommentContainer = ({ data, fetch }) => {
    const { id } = useParams();
    const [rate, setRate] = useState(0);
    const [comment, setComment] = useState("");
    const addComment = () => {
        const formdata = new FormData();
        formdata.append("rating", rate);
        formdata.append("review", comment);
        axiosInstance.post(`user/blogs/${id}/reviews`,formdata).then((response) => {
            if (response?.data?.status) {
                fetch();
                toast.success(response?.data?.response);
            }
        });
    };
    return (
        <div className="comment-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-xl-8">
                        <SectionTitle
                            classOption="d-flex flex-column-reverse m-0"
                            title="<span>Leave</span> A Comment"
                            // subTitle="Your email address will not be published"
                        />
                        <Rating
                            initialRating={rate}
                            fullSymbol={"icofont-star text-warning fs-4"}
                            emptySymbol={"icofont-star fs-4"}
                            onChange={(rate) => setRate(rate)}
                        />
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                class="form-control border-0 border-bottom bg-transparent"
                                placeholder="Comment...."
                                onChange={(e) => setComment(e.target.value)}
                            />
                            <button
                                class="btn btn-outline-primary"
                                type="button"
                                onClick={addComment}
                            >
                                Send
                            </button>
                        </div>{" "}
                        <div className="comment-form-wrap">
                            {data?.reviews?.length > 0 ? (
                                data?.reviews.map((review) => (
                                    <section>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-5 col-md-6 col-12 pb-4">
                                                    <div className="comment mt-4 text-justify float-left">
                                                        <h4>
                                                            {
                                                                review?.user
                                                                    ?.fname
                                                            }{" "}
                                                            {
                                                                review?.user
                                                                    ?.lname
                                                            }
                                                        </h4>
                                                        <span>
                                                            <Rating
                                                                initialRating={
                                                                    review?.rating
                                                                }
                                                                readonly
                                                                fullSymbol={
                                                                    "icofont-star text-warning fs-5"
                                                                }
                                                                stop={
                                                                    review?.rating
                                                                }
                                                            />
                                                        </span>

                                                        <br />
                                                        <p>{review?.review}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                ))
                            ) : (
                                <p className="text-center">No Comment Added</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentContainer;
