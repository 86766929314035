import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { axiosInstance } from "../config/https";
import Breadcrumb from "../containers/global/breadcrumb/index.jsx";
import ProServiceDetailsContainer from "../containers/service-details/Proindex";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index.jsx";

const ProServiceDetails = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({});
    const { id } = useParams();
    const history = useHistory();
    const Details = () => {
        setLoader(true);
        axiosInstance
            .get(`user/professionals/${id}`)
            .then((response) => {
                console.table(response);
                const Data = response.data.data;
                if (response.data.status === true) {
                    setData(Data);
                    setLoader(false);
                } else {
                    toast.error(response.data.error);
                    history.push("/FindProfressional");
                    setLoader(false);
                }
            })
            .catch((errors) => {
                setLoader(false);
            });
    };
    useEffect(() => {
        Details();
    }, []);
    return (
        <React.Fragment>
            {loader ? <div className="loader"></div> : null}

            <Layout>
                <SEO title="Avanzando juntos – Service Details" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <Breadcrumb
                            prevs={[
                                { text: "Home", path: "/" },
                                {
                                    text: "Professional Care",
                                    path: "/FindProfressional",
                                },
                            ]}
                            contentThree={data?.fname + data?.lname}
                        />
                        <ProServiceDetailsContainer data={data} />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ProServiceDetails;
