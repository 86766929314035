import AOS from "aos";
import "aos/dist/aos.css";
import "lightgallery.js/dist/css/lightgallery.css";
import Pusher from "pusher-js";
import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss";
import "./App.css";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/icofont.css";
import "./assets/scss/style.scss";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import Chat from "./components/Chat/Chat";
import ProChat from "./components/Chat/ProChat";
import NavScrollTop from "./components/nav-scroll-top";
import i18n from "./config/Translator.js";
import Dummypage from "./pages/Dummy";
import Packages from "./pages/Packages";
import Success from "./pages/Success";
import AboutPage from "./pages/about";
import BlogPage from "./pages/blog";
import Booking from "./pages/booking";
import Building from "./pages/building";
import ContactPage from "./pages/contact";
import FindProfessional from "./pages/findpros";
import HomePage from "./pages/index";
import Media from "./pages/media";
import ServicePage from "./pages/service";
import ProServiceDetails from "./templates/Proservice-details";
import AdsDetailsPage from "./templates/ads-details";
import BlogAuthor from "./templates/blog-author";
import BlogCategory from "./templates/blog-category";
import BlogDate from "./templates/blog-date";
import BlogDetailsPage from "./templates/blog-details";
import BlogTag from "./templates/blog-tag";
import BusinessDetail from "./templates/businesse-details";
import ServiceDetails from "./templates/service-details";
// import GetStarted from "./components/Chat/VideoCalling.js";
import VideoCalling from "./VideoCalling/VideoCalling.js";
import Comment from "./components/comment/index.jsx";

const App = () => {
    const openVideoCallWindow = (token) => {
        try {
            const videoCallWindow = window.open(
                `/join_meeting?token=${token}`,
                "_blank",
                "width=800, height=600"
            );
            if (videoCallWindow) {
                // Popup opened successfully
                toast.success("Video call window opened successfully");
            } else {
                // Popup blocked, open in a new tab
                window.location.href = `/join_meeting?token=${token}`;
            }
        } catch (error) {
            toast.error("Error opening video call window:", error);
        }
    };
    const path = window.location.pathname;
    const isLogin = localStorage.getItem("accessToken");
    useEffect(() => {
        AOS.init({
            offset: 80,
            duration: 1000,
            once: true,
            easing: "ease",
        });
        AOS.refresh();
    }, []);
    // Chatting
    if (isLogin) {
        let UserDetails = localStorage?.getItem("userDetails");
        UserDetails = JSON?.parse(UserDetails);

        useEffect(() => {
            if (isLogin) {
                const pusher = new Pusher("a735ad8627d543e8039e", {
                    cluster: "ap2",
                    //   encrypted: true,
                });
                const channel = pusher.subscribe(
                    `user-receive-message-${UserDetails?.id}`
                );
                channel.bind(`carehome-send-message`, (data) => {
                    console.log(data, "message sent");
                    if (path === "/Chat/:id" || path === "/Chat") {
                    } else {
                        toast.success("New Message has Been Arrived");
                    }
                });
                console.log(channel, "channel");
                return () => {
                    pusher.unsubscribe(
                        `user-receive-message-${UserDetails?.id}`
                    );
                };
            } else {
            }
        }, []);
        useEffect(() => {
            if (isLogin) {
                const pusher = new Pusher("a735ad8627d543e8039e", {
                    cluster: "ap2",
                    //   encrypted: true,
                });
                const channel = pusher.subscribe(
                    `user-receive-message-${UserDetails?.id}`
                );
                channel.bind(`professional-send-message`, (data) => {
                    console.log(data, "message sent");
                    if (path === "/Chat/:id" || path === "/Chat") {
                    } else {
                        toast.success("New Message has Been Arrived");
                    }
                });
                console.log(channel, "channel");
                return () => {
                    pusher.unsubscribe(
                        `user-receive-message-${UserDetails?.id}`
                    );
                };
            } else {
            }
        }, []);
    }
    // VideoCalling
    if (isLogin) {
        let UserDetails = localStorage?.getItem("userDetails");
        UserDetails = JSON?.parse(UserDetails);

        useEffect(() => {
            if (isLogin) {
                const pusher = new Pusher("a735ad8627d543e8039e", {
                    cluster: "ap2",
                    //   encrypted: true,
                });
                const channel = pusher.subscribe(
                    `user-receive-call-${UserDetails?.id}`
                );
                channel.bind(`carehome-dial-call`, (data) => {
                    console.log(data, "message sent");
                    if (
                        !window.location.pathname.includes("createMeeting") ||
                        !window.location.pathname.includes("join_meeting")
                    ) {
                        openVideoCallWindow(data?.meeting_id);

                        // Show a toast notification
                        toast.success("Someone is calling you");
                    }
                });
                console.log(channel, "channel");
                return () => {
                    pusher.unsubscribe(`user-receive-call-${UserDetails?.id}`);
                };
            } else {
            }
        }, []);
        useEffect(() => {
            if (isLogin) {
                const pusher = new Pusher("a735ad8627d543e8039e", {
                    cluster: "ap2",
                    //   encrypted: true,
                });
                const channel = pusher.subscribe(
                    `user-receive-call-${UserDetails?.id}`
                );
                channel.bind(`professional-dial-call`, (data) => {
                    if (
                        !window.location.pathname.includes("createMeeting") ||
                        !window.location.pathname.includes("join_meeting")
                    ) {
                        openVideoCallWindow(data?.meeting_id);

                        // Show a toast notification
                        toast.success("Someone is calling you");
                    }
                });
                console.log(channel, "channel");
                return () => {
                    pusher.unsubscribe(`user-receive-call-${UserDetails?.id}`);
                };
            } else {
            }
        }, []);
    }

    return (
        <>
            <I18nextProvider i18n={i18n}>
                <Router>
                    {/* Same as */}
                    <NavScrollTop>
                        <Switch>
                            <Route path={`/`} exact component={HomePage} />
                            <Route path={`/`} exact component={HomePage} />
                            <Route path={`/Comment`} exact component={Comment} />
                            <Route
                                path={`/booking`}
                                exact
                                component={Booking}
                            />
                            <Route path={`/Chat`} exact component={Chat} />
                            <Route
                                path={`/professionalchat`}
                                exact
                                component={ProChat}
                            />
                            <Route
                                path={`/professionalchat/:id`}
                                exact
                                component={ProChat}
                            />
                            <Route path={`/Chat/:id`} exact component={Chat} />
                            <Route
                                path={`/Success`}
                                exact
                                component={Success}
                            />
                            <Route path={`/SignUp`} exact component={Signup} />
                            <Route
                                path={`/Packages`}
                                exact
                                component={Packages}
                            />
                            <Route path={`/about`} component={AboutPage} />
                            <Route path={`/Media`} component={Media} />
                            <Route path={`/service`} component={ServicePage} />
                            <Route
                                path={`/FindProfressional`}
                                component={FindProfessional}
                            />
                            <Route
                                path={`/service-details/:id`}
                                component={ServiceDetails}
                            />
                            <Route
                                path={`/building/:id/:director`}
                                component={Building}
                            />
                            <Route
                                path={`/proservice-details/:id`}
                                component={ProServiceDetails}
                            />
                            <Route
                                path={`/business_detail/:id`}
                                component={BusinessDetail}
                            />
                            <Route path={`/blog`} component={BlogPage} />
                            <Route
                                path={`/category/:slug`}
                                component={BlogCategory}
                            />
                            <Route path={`/tag/:slug`} component={BlogTag} />
                            <Route path={`/date/:date`} component={BlogDate} />
                            <Route
                                path={`/author/:author`}
                                component={BlogAuthor}
                            />
                            <Route
                                path={`/blog-details/:id`}
                                component={BlogDetailsPage}
                            />
                            <Route
                                path={`/ads-details/:id`}
                                component={AdsDetailsPage}
                            />
                            <Route
                                path={`/perview/:id`}
                                component={Dummypage}
                            />
                            <Route path={`/contact`} component={ContactPage} />
                            <Route path={`/Login`} component={Login} />
                            {/* <Route
                            path={`/VideoCalling`}
                            component={VideoCalling}
                        /> */}
                            <Route
                                path={`/createMeeting/:parti_id/:role`}
                                component={VideoCalling}
                            />
                            <Route
                                path={`/join_meeting`}
                                component={VideoCalling}
                            />
                        </Switch>
                    </NavScrollTop>
                </Router>
            </I18nextProvider>
        </>
    );
};

export default App;
