import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import first from "../assets/img/01.jpg";
import second from "../assets/img/02.jpg";
import third from "../assets/img/03.jpg";
import ScrollToTop from "../components/scroll-to-top";
import SectionTitle from "../components/section-title";
import SEO from "../components/seo";
import AppointmentContainer from "../containers/home/appointment";
import TeamContainer from "../containers/home/team";
import TestimonialContainer from "../containers/home/testimonial";
import WhyAvan from "../containers/whyAvan";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index.jsx";
import Reviews from "./reviews";
const HomePage = () => {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isEmailVerified = queryParams.get("email_verified") === "true";
    useEffect(() => {
        if (isEmailVerified) {
            toast.success("Email verified successfully!");
            history.push("/");
        }
    }, []);
    const { t } = useTranslation();
    const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

    useEffect(() => {
        const storedDisclaimerAccepted =
            localStorage.getItem("disclaimerAccepted");
        if (storedDisclaimerAccepted === "true") {
            setDisclaimerAccepted(true);
        }
    }, []);

    // Function to handle disclaimer acceptance
    const acceptDisclaimer = () => {
        setDisclaimerAccepted(true);
        localStorage.setItem("disclaimerAccepted", "true"); // Store the value in local storage
    };

    return (
        <React.Fragment>
            <Layout>
                {!disclaimerAccepted && (
                    <div className="disclaimer-overlay">
                        <div className="disclaimer-content">
                            {/* Add your disclaimer text and accept button */}
                            <div className="my-auto">
                                <h3 className="text-center capitalizing">
                                    DISCLAIMER
                                </h3>
                                <p className="text-center home-text">
                                    Según los términos de este acuerdo, usted
                                    acepta indemnizar y eximir a Avanzando
                                    Juntos y a sus proveedores, junto con sus
                                    respectivos directores, funcionarios,
                                    empleados y agentes, de cualquier reclamo y
                                    gasto, incluidos los honorarios de abogados,
                                    que surjan de (1 ) Su uso de la Plataforma,
                                    incluida cualquier violación de esta
                                    Política de privacidad, Términos de servicio
                                    o leyes aplicables. (2) La recopilación, el
                                    uso, el almacenamiento o la divulgación de
                                    información personal de acuerdo con esta
                                    Política de privacidad, siempre que cumpla
                                    con las leyes aplicables. (3) Cualquier
                                    contenido o material que envíe a través de
                                    la Plataforma, y ​​cualquier reclamo o
                                    disputa con terceros. (4) Cualquier atención
                                    brindada a cualquier usuario o persona(s)
                                    para quienes Usted busca atención a través
                                    de la plataforma. Avanzando Juntos solo
                                    facilita la comunicación entre los usuarios
                                    y no brinda ni se responsabiliza de las
                                    negociaciones o la atención en sí. Los
                                    usuarios son los únicos responsables de
                                    evaluar y seleccionar los proveedores de
                                    atención y garantizar la idoneidad y la
                                    calidad de la atención brindada. (4)
                                    Cualquier proveedor, equipo o instalación de
                                    terceros utilizados durante el horario
                                    comercial asignado o fuera de este en
                                    relación con cualquier arreglo realizado en
                                    la plataforma o fuera de la Plataforma.
                                    Avanzando Juntos no respalda, controla ni se
                                    hace responsable de los proveedores, equipos
                                    o instalaciones, y usted es el único
                                    responsable de cualquier interacción,
                                    acuerdo o arreglo que se realice con ellos.
                                    Esta indemnización se aplica
                                    independientemente de que la negligencia de
                                    Avanzando Juntos o de sus proveedores sea la
                                    causa o se deba a otras circunstancias. (6)
                                    Acceso o uso no autorizado de su cuenta.
                                    <br />
                                    Al usar Avanzando Juntos, usted reconoce y
                                    acepta los términos de esta cláusula de
                                    indemnización y asume la responsabilidad por
                                    cualquier reclamo, responsabilidad, daño,
                                    pérdida, costo o gasto que surja de su uso
                                    de la Plataforma o cualquier atención
                                    brindada por los usuarios a través de la
                                    Plataforma. , incluida cualquier interacción
                                    con proveedores, equipos o instalaciones.
                                </p>
                                <button
                                    className="float-end btn btn-primary"
                                    onClick={acceptDisclaimer}
                                >
                                    I Accept It
                                </button>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                )}
                <SEO title="Avanzando juntos – " />
                <div className="wrapper home-default-wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <div className="intro-section">
                            <img
                                src="./images/03.png"
                                alt=""
                                className="img-fluid w-100"
                            />
                        </div>{" "}
                        <section className="mt-5">
                            <div className="container capitalizing">
                                <SectionTitle
                                    classOption="text-center  capitalizing mb-0 "
                                    title={`${t("welcome")}`}
                                    home_heading={"home-heading text-dark"}
                                />
                                <p className="text-center home-text capitalizing fs-4">
                                    {t(
                                        `at_avanzando_juntos_corporation,_we_are_convinced_that_finding_the_right_care_is_a_process_that_requires_compassion,_understanding,_and_trust._we_understand_the_importance_of_this_process_and_are_here_to_support_you_every_step_of_the_way.`
                                    ).replace(/_/g, " ")}
                                </p>
                                <p className="text-center home-text capitalizing fs-4">
                                    {t(
                                        `our_mission_is_based_on_the_idea_that_exceptional_care_should_be_accessible_to_everyone._that's_why_we_have_created_a_platform_dedicated_to_simplifying_the_search_for_care._whether_you_are_looking_for_care_for_yourself_or_a_loved_one,_we_understand_that_the_decision-making_process_can_be_challenging_both_emotionally_and_logistically._this_is_where_we_step_in_to_provide_support.`
                                    ).replace(/_/g, " ")}
                                </p>
                            </div>
                        </section>
                        <section className="mt-5">
                            <div className="container capitalizing">
                                <SectionTitle
                                    classOption="text-center capitalizing mb-0 "
                                    title={`${t("families")}`}
                                    home_heading={"home-heading text-dark"}
                                />
                                <p className="text-center home-text capitalizing fs-4">
                                    {t(
                                        `we_understand_how_challenging_it_can_be_to_find_the_right_care_for_your_elderly_loved_ones._at_avanzando_juntos,_we_are_here_to_make_that_process_easier._our_platform_offers_options_carefully_selected_that_cater_to_the_specific_needs_of_your_family_member._we_prioritize_their_\nwell-being_and_comfort,_whether_they_require_home_care,_assisted_living,_or_other_specialized_services._we_are_committed_to_guiding_you_with_compassion_and_support_every_step_of_the_way`
                                    ).replace(/_/g, " ")}
                                </p>
                            </div>
                        </section>
                        <section className="mt-5">
                            <div className="container capitalizing">
                                <SectionTitle
                                    classOption="text-center capitalizing mb-0 "
                                    title={`${t("workers")}`}
                                    home_heading={"home-heading text-dark"}
                                />
                                <p className="text-center home-text capitalizing fs-4">
                                    {t(
                                        `joining_our_platform_means_being_part_of_a_community_that_values_compassion,_dedication,_\r\nand_selflessness._at_avanzando_juntos,_we_understand_that_your_role_goes_beyond_just_a_\r\njob;_it's_a_calling_to_bring_comfort,_joy,_and_security_to_those_in_need.`
                                    ).replace(/_/g, " ")}
                                </p>
                                <p className="text-center home-text capitalizing fs-4">
                                    {t(
                                        `we_welcome_healthcare_providers_like_you_who_are_committed_to_making_a_positive_impact._\r\nour_platform_is_designed_with_your_needs_in_mind,_offering_flexibility,_work-life_balance,_and_\r\nfair_compensation_tailored_to_your_schedule,_preferences,_and_salary_expectations._join_us_\r\nin_making_a_difference_where_it_matters_most.`
                                    ).replace(/_/g, " ")}
                                </p>
                            </div>
                        </section>
                        <section className="mt-5">
                            <div className="container capitalizing">
                                <SectionTitle
                                    classOption="text-center capitalizing mb-0 "
                                    title={`${t("establishment")}`}
                                    home_heading={"home-heading text-dark"}
                                />
                                <p className="text-center home-text capitalizing fs-4">
                                    {t(
                                        `establishments_for_older_adults_play_a_vital_role_in_providing_specialized_care_for_our_loved_\r\nones,_and_making_informed_decisions_ensures_they_receive_the_exceptional_support_and_\r\nattention_they_deserve._apart_from_assisting_families_in_locating_the_optimal_care_for_their_\r\nloved_ones,_we’ve_taken_an_extra_step_to_aid_nursing_homes_in_promoting_their_services_and_\r\navailable_beds._through_providing_families_with_precise_and_current_details_regarding_\r\nnursing_homes,_we_cultivate_a_transparent_and_supportive_environment_for_caregivers_and_\r\ncare_providers_alike._`
                                    ).replace(/_/g, " ")}
                                </p>
                            </div>
                        </section>
                        {/* <HealthFeatureContainer /> */}
                        {/* <MedicalFeatureContainer /> */}
                        <section className="feature-section bg-white mb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div
                                            className="row icon-box-style"
                                            data-aos="fade-up"
                                            data-aos-duration="1100"
                                        >
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <img
                                                        src={first}
                                                        alt=""
                                                        width={"100%"}
                                                        style={{ height: 200 }}
                                                        className="img-thumbnail"
                                                    />
                                                    <div className="text-center">
                                                        <Link
                                                            to={
                                                                "FindProfressional"
                                                            }
                                                        >
                                                            <button className="btn btn-theme rounded mt-3 w-100 p-3 text-captilaize">
                                                                {t(
                                                                    "encuentra cuido"
                                                                )}{" "}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div className="card border-1 capitalizing border-primary p-3 text-center mt-5 home-text">
                                                        {t(
                                                            `finding_the_right_care_for_yourself_or_your_loved_ones_can_be_a_daunting_task._that's_why_our_website_is_here_to_simplify_and_revolutionize_the_process,_ensuring_that_you_find_personalized,_compassionate_care_that_meets_your_unique_needs_and_preferences.`
                                                        ).replace(/_/g, " ")}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <img
                                                        src={second}
                                                        alt=""
                                                        width={"100%"}
                                                        style={{ height: 200 }}
                                                        className="img-thumbnail"
                                                    />
                                                    <div className="text-center">
                                                        <Link to={"service"}>
                                                            <button className="btn btn-theme rounded mt-3 w-100 p-3 capitalizing">
                                                                {t(
                                                                    "encuentra hogar"
                                                                )}{" "}
                                                            </button>
                                                        </Link>
                                                    </div>

                                                    <div className="card border-1 capitalizing border-primary p-3 text-center mt-5 home-text">
                                                        {t(
                                                            `with_just_a_few_clicks,_you_can_narrow_your_search,_access_detailed_information,_and_compare_different_nursing_homes_side_by_side._our_innovative_filtering_system_allows_you_to_customize_your_search_based_on_crucial_factors_like_price,_location,_services_provided,_facility_ratings,_and_patient_reviews._by_taking_advantage_of_this_tool,_you_can_confidently_navigate_the_complex_world_of_nursing_home_selection,_saving_you_precious_time_and_ensuring_peace_of_mind.`
                                                        ).replace(/_/g, " ")}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <img
                                                        src={third}
                                                        alt=""
                                                        width={"100%"}
                                                        style={{ height: 200 }}
                                                        className="img-thumbnail"
                                                    />
                                                    <div className="text-center">
                                                        <a
                                                            href="https://business.avanzandojuntos.net/"
                                                            target="_blank"
                                                        >
                                                            <button className="btn btn-theme rounded mt-3 w-100 p-3 capitalizing">
                                                                {t(
                                                                    "encuentra trabajo"
                                                                )}{" "}
                                                            </button>
                                                        </a>
                                                    </div>
                                                    <div className="card border-1 capitalizing border-primary p-3 text-center mt-5 home-text">
                                                        {t(
                                                            `are_you_seeking_new_and_rewarding_job_opportunities_that_allow_you_to_make_a_meaningful_impact_on_the_lives_of_elderly_individuals_and_those_with_specific_needs?_look_no_further_than_our_communication_and_services_platform,_exclusively_designed_for_professionals_like_you._set_you_own_schedule,_hours_and_salary._your_in_charge`
                                                        ).replace(/_/g, " ")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <AppointmentContainer />
                        {/* <BrandContainer /> */}
                        <TestimonialContainer />
                        <Reviews />
                        {/* <PricingContainer /> */}
                        {/* <BlogAccordion /> */}
                        {/* <GoogleMapContainer /> */}
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
