import PropTypes from "prop-types";

const SectionTitle = ({ subTitle, title, classOption, home_heading }) => {
    return (
        <div className={`section-title ${classOption}`}>
            <p>{subTitle}</p>

            <h2
                className={`title ${home_heading}  capitalizing`}
                dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
        </div>
    );
};

SectionTitle.propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
    classOption: PropTypes.string,
};

SectionTitle.defaultProps = {
    classOption: "section-title",
};

export default SectionTitle;
