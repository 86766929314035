import React from "react";
import PricingContainer from "../containers/global/pricing";

const Packages = () => {
    return (
        <>
            <PricingContainer />
        </>
    );
};

export default Packages;
