import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import ReviewsIndex from "../components/reviewsindex";
import { t } from "i18next";
import { axiosInstance } from "../config/https";
import Rating from "react-rating";
import { toast } from "react-toastify";

const Reviews = () => {
    const [data, setData] = useState([]);
    const [rate, setRate] = useState(0);
    const [values, setValues] = useState();
    const onChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    const getReviews = () => {
        axiosInstance.get("reviews").then((response) => {
            setData(response.data.data);
        });
    };
    const addReviews = (event) => {
        event.preventDefault();
        const formdata = new FormData();
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                formdata.append(key, values[key]);
            }
            formdata.append("rating", rate);
        }
        axiosInstance.post("reviews", formdata).then((response) => {
            const { status, response: message, error } = response.data;
            if (status) {
                getReviews();
                toast.success(message);
                document.getElementById("myForm").reset();
            } else {
                toast.success(error);
            }
        });
    };
    useEffect(() => {
        getReviews();
    }, []);

    return (
        <>
            <div className="bg-dark">
                <div className="container py-5 ">
                    <SectionTitle
                        classOption="text-center mb-0"
                        title={`<span class="text-white">${t(
                            "reviews"
                        )}</span>`}
                    />
                    <div className="row ">
                        <Rating
                            initialRating={rate}
                            fullSymbol={"icofont-star text-warning fs-4"}
                            emptySymbol={"icofont-star fs-4"}
                            onChange={(rate) => setRate(rate)}
                        />
                        <div className="col-md-6">
                            <form id="myForm" onSubmit={addReviews}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="text-white">
                                            {t("first_name")}
                                        </label>
                                        <input
                                            type="text"
                                            name="fname"
                                            className="form-control"
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="text-white">
                                            {t("last_name")}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChange}
                                            name="lname"
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="text-white">
                                            {t("reviews")}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            onChange={onChange}
                                            name="review"
                                        />
                                    </div>
                                    <div className="text-right">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            {t("add_review")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <ReviewsIndex data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reviews;
