import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/button";
import HeaderContactInfo from "../../components/header-contact-info";
import Logo from "../../components/logo";
import MainMenu from "../../components/menu/main-menu";
import MenuOverlay from "../../components/menu/menu-overlay";
import MobileMenu from "../../components/menu/mobile-menu";
import HomeData from "../../data/home.json";

const Header = ({ sendingCheck }) => {
    let UserDetails = localStorage.getItem("userDetails");
    UserDetails = JSON.parse(UserDetails);
    const isLogin = localStorage.getItem("accessToken");

    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow((prev) => !prev);
    };
    console.log(ofcanvasShow);
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);
    useEffect(() => {
        const header = document.querySelector(".sticky-header");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = ({ }) => {
        setScroll(window.scrollY);
    };
    useEffect(() => {
        if (isLogin) {
            const pusher = new Pusher("a735ad8627d543e8039e", {
                cluster: "ap2",
                //   encrypted: true,
            });
            const channel = pusher.subscribe(
                `user-receive-message-${UserDetails.id}`
            );
            channel.bind(`carehome-send-message`, (data) => {
                console.log(data, "message sent");
                sendingCheck(true);
            });
            console.log(channel, "channel");
            return () => {
                pusher.unsubscribe(`user-receive-message-${UserDetails.id}`);
            };
        }
    }, []);
    useEffect(() => {
        if (isLogin) {
            const pusher = new Pusher("a735ad8627d543e8039e", {
                cluster: "ap2",
                //   encrypted: true,
            });
            const channel = pusher.subscribe(
                `user-receive-message-${UserDetails.id}`
            );
            channel.bind(`professional-send-message`, (data) => {
                console.log(data, "message sent");
                sendingCheck(true);
            });
            console.log(channel, "channel");
            return () => {
                pusher.unsubscribe(`user-receive-message-${UserDetails.id}`);
            };
        }
    }, []);

    const { t, i18n } = useTranslation();
    const changeLanguage = () => {
        const currentLanguage = i18n.language;
        const newLanguage = currentLanguage === "es" ? "en" : "es";
        i18n.changeLanguage(newLanguage);
    };

    return (
        <Fragment>
            <header className="header">
                {/* <div className="header-top d-none d-lg-block">
                    <div className="container">
                        <div className="row row-cols-2">
                            <div className="col">
                                <p>
                                    <i className="icofont-google-map"></i>{" "}
                                    <span>ADDRESS:</span> 568 Elizaberth Str,
                                    London, UK
                                </p>
                            </div>
                            <div className="col">
                                <ul className="social-links text-end">
                                    <li>
                                        <SocialIcon
                                            path="https://twitter.com/"
                                            icon="icofont-twitter"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            path="https://www.facebook.com/"
                                            icon="icofont-facebook"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            path="https://www.instagram.com/"
                                            icon="icofont-instagram"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            path="https://rss.com/"
                                            icon="icofont-rss-feed"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            path="https://www.youtube.com/"
                                            icon="icofont-play-alt-1"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className={`header-middle mobile-sticky`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="header-middle-content">
                                    <div className="header-logo w-25">
                                        <Logo
                                            image={`${process.env.PUBLIC_URL}/img/logo.png`}
                                        />
                                    </div>
                                    <ul className="media-wrap d-none d-lg-flex">
                                        {HomeData[0].headerInfo &&
                                            HomeData[0].headerInfo.map(
                                                (single, key) => {
                                                    return (
                                                        <HeaderContactInfo
                                                            key={key}
                                                            data={single}
                                                        />
                                                    );
                                                }
                                            )}
                                    </ul>
                                    <Button
                                        path={process.env.PUBLIC_URL + "/"}
                                        classOption="book-now-btn d-none d-sm-inline-block d-lg-none"
                                        text="book an appointment"
                                    />
                                    <div className="mobile-menu-toggle d-lg-none">
                                        <button
                                            onClick={onCanvasHandler}
                                            className="offcanvas-toggle"
                                        >
                                            <svg viewBox="0 0 800 600">
                                                <path
                                                    d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                                    id="top"
                                                ></path>
                                                <path
                                                    d="M300,320 L540,320"
                                                    id="middle"
                                                ></path>
                                                <path
                                                    d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                                    id="bottom"
                                                    transform="translate(480, 320) scale(1, -1) translate(-480, -318)"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-bottom d-none d-lg-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <MainMenu />
                                    <Button
                                        path={process.env.PUBLIC_URL + "/"}
                                        classOption="book-now-btn"
                                        text={`${t("book_an").replace(
                                            /_/g,
                                            " "
                                        )} ${t('appointment')}`}
                                    />
                                    <button
                                        className="btn"
                                        onClick={changeLanguage}
                                    >
                                        {i18n.language === "en" ? (
                                            <img
                                                src="/flag/portugal.png"
                                                width="20"
                                                height="20"
                                                alt="es"
                                                title={`Change language to ${i18n.language === "en"
                                                        ? "Spainsh"
                                                        : "English"
                                                    }`}
                                            />
                                        ) : (
                                            <img
                                                src="/flag/united-states.png"
                                                width="20"
                                                height="20"
                                                alt="en"
                                                title={`Change language to ${i18n.language === "es"
                                                        ? "English"
                                                        : "Spainsh"
                                                    }`}
                                            />
                                        )}
                                        {/* {i18n.language.toUpperCase()} */}
                                    </button>
                                    {/* <select
                                        className=""
                                        onChange={(e) =>
                                            changeLanguage(e.target.value)
                                        }
                                    >
                                        <option value="es" selected>
                                            <i className="fa fa-user" /> ES
                                        </option>
                                        <option value="en">
                                            <i className="fa fa-user" /> EN
                                        </option>
                                    </select> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`header-bottom sticky-header d-none d-lg-block ${scroll > headerTop ? "sticky" : ""
                        }`}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <MainMenu />
                                    <Button
                                        path={process.env.PUBLIC_URL + "/"}
                                        classOption="book-now-btn"
                                        text={`${t("book_an").replace(
                                            /_/g,
                                            " "
                                        )} ${t('appointment')}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MenuOverlay show={ofcanvasShow} />
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
        </Fragment>
    );
};

export default Header;
