import React from "react";
import PageTitle from "../../../components/page-title";
import PropTypes from "prop-types";

const PageTitleContainer = ({ subTitle, title, image }) => {
    const publicUrl = process.env.PUBLIC_URL;
    return (
        <div
            className="page-title-area bg-img bg-img-top"
            style={{
                backgroundImage: `url(${publicUrl}${image})`,
                backgroundSize: "100% auto",
            }}
        >
            <div class="overlay"></div>
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-6 col-xl-7 m-auto"
                        data-aos="fade-up"
                    >
                        <PageTitle
                            subTitle={subTitle}
                            title={title}
                            text_class={"fs-1 capitalizing"}
                        />
                    </div>
                </div>
            </div>
            <style>{`.page-title-area {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Change the last value to adjust the opacity (0 to 1) */
  pointer-events: none; /* This ensures that the overlay won't block clicks on underlying elements */
}
`}</style>
        </div>
    );
};

PageTitleContainer.propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
};

export default PageTitleContainer;
