import SectionTitle from "../../../components/section-title";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import HomeData from "../../../data/home.json";
import Testimonial from "../../../components/testimonial";
import { axiosInstance } from "../../../config/https";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

SwiperCore.use([Autoplay]);
const TestimonialContainer = () => {
    const [brand, setBrand] = useState([]);
    const getBrand = () => {
        axiosInstance
            .get("advertisements")
            .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setBrand(response?.data?.data);
                }
            })
            .catch((errors) => {
                console.log(errors);
            });
    };
    useEffect(() => {
        getBrand();
    }, []);
    const swiperOption = {
        loop: true,
        slidesPerView: 3,
        speed: 1000,
        spaceBetween: 100,
        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },
    };
    return (
        <>
            {brand.length !== 0 ? (
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-12"
                            data-aos="fade-up"
                            data-aos-duration="1100"
                        >
                            <SectionTitle
                                classOption="text-center mb-0"
                                title="<span>Business Advertisement</span>"
                            />
                            <Swiper
                                className="testimonial-slider-container"
                                {...swiperOption}
                            >
                                {brand.map((single, key) => {
                                    const updated_at = single.updated_at;

                                    // Convert to Date object
                                    const dateObj = new Date(updated_at);

                                    // Format date (DD MMMM YY)
                                    const options = {
                                        day: "2-digit",
                                        month: "long",
                                        year: "2-digit",
                                    };
                                    const dateFormatter =
                                        new Intl.DateTimeFormat(
                                            "en-US",
                                            options
                                        );
                                    const dateFormatted =
                                        dateFormatter.format(dateObj);

                                    // Time format 12-hour (hh:mm AM/PM)
                                    const hours = dateObj.getHours();
                                    const minutes = dateObj.getMinutes();
                                    const ampm = hours >= 12 ? "PM" : "AM";
                                    const hours12 = hours % 12 || 12;
                                    const timeFormatted = `${hours12}:${minutes
                                        .toString()
                                        .padStart(2, "0")} ${ampm}`;

                                    return (
                                        <SwiperSlide key={key}>
                                            <section className=" my-5">
                                                <Link
                                                    to={`/ads-details/${single.id}`}
                                                >
                                                    <img
                                                        src={single.image}
                                                        alt=""
                                                        className="img-thumbnail"
                                                        style={{
                                                            height: 193,
                                                            width: 300,
                                                        }}
                                                    />
                                                    <h4 className="text-primary capitalizing mt-1">
                                                        {single.title}
                                                    </h4>
                                                </Link>
                                                <p>
                                                    {dateFormatted}{" "}
                                                    {timeFormatted} by{" "}
                                                    <a
                                                        href={single.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {single.business.title}
                                                    </a>
                                                </p>
                                            </section>
                                        </SwiperSlide>
                                    );
                                })}
                                {brand.length > 3 ? (
                                    <>
                                        <div className="swiper-button-next"></div>
                                        <div className="swiper-button-prev"></div>
                                    </>
                                ) : null}
                            </Swiper>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default TestimonialContainer;
