import SectionTitle from "../../../components/section-title";
import PricingItem from "../../../components/pricing";
import HomeData from "../../../data/home.json";
import PropTypes from "prop-types";
import { useState } from "react";
import { axiosInstance } from "../../../config/https";
import { useEffect } from "react";

const PricingContainer = ({ pricingClassOption }) => {
    const [newpackage, setPackage] = useState([]);
    const [coupon, setCoupon] = useState("");
    useEffect(() => {
        axiosInstance
            .get("/plans")
            .then((e) => {
                console.log(e);
                if (e.data.status === true) {
                    setPackage(e.data.data);
                }
            })
            .catch((e) => console.log(e));
    }, []);

    return (
        <div className="pricing-area pricing-default-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Pricing List"
                            title="<span>Protect</span> With Health Care Card"
                        />
                    </div>
                </div>
                <input
                    type="text"
                    className="form-control w-25 "
                    onChange={(e) => setCoupon(e.target.value)}
                    placeholder="You Have any Coupon Code"
                />
                <br />
                <div
                    className={`row row-gutter-0 pricing-items-style1 ${pricingClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                >
                    {newpackage &&
                        newpackage
                            .filter((e) => e.type === "User")
                            .map((single, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="col-sm-6 col-md-6 col-lg-3"
                                    >
                                        <PricingItem
                                            key={key}
                                            data={single}
                                            coupon={coupon}
                                        />
                                    </div>
                                );
                            })}
                </div>
            </div>
        </div>
    );
};

PricingContainer.propTypes = {
    pricingClassOption: PropTypes.string,
};
PricingContainer.defaultProps = {
    pricingClassOption: "row row-gutter-0 pricing-items-style1",
};

export default PricingContainer;
