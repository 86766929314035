import PropTypes from "prop-types";
import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Breadcrumb from "../containers/global/breadcrumb/index.jsx";
import BlogDetailsContainer from "../containers/blog/blog-details";
import CommentContainer from "../containers/comment-container/index.jsx";
import Footer from "../layouts/footer";
import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { useState } from "react";
import { axiosInstance } from "../config/https.js";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
const BlogDetailsPage = () => {
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({});
    const singleBlog = () => {
        setLoader(true);
        axiosInstance
            .get(`posts/${id}`)
            .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setData(response.data.data);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((errors) => {
                setLoader(false);
                console.log(errors);
            });
    };
    useEffect(() => {
        singleBlog();
    }, []);

    return (
        <React.Fragment>
            {loader ? <div className="loader"></div> : null}
            <Layout>
                <SEO title="Avanzando juntos – Blog Details" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <Breadcrumb
                            prevs={[
                                { text: "Home", path: "/" },
                                { text: "Blog", path: "/blog" },
                            ]}
                            contentThree={data?.title}
                        />
                        <BlogDetailsContainer data={data} />
                        <CommentContainer data={data} fetch={singleBlog} />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

BlogDetailsPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default BlogDetailsPage;
