import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import Footer from "../layouts/footer";
import AboutContainer from "../containers/about";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import IntroContainer from "../containers/home/intro";
import { useTranslation } from "react-i18next";
import TeamContainer from "../containers/home/team/index.jsx";
import WhyAvan from "../containers/whyAvan/index.js";

const AboutPage = () => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Avanzando juntos – About" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <IntroContainer />
                        {/* <PageTitleContainer
                            image="Video/about.gif"
                            subTitle="Avanzando juntos medical center"
                            title="About <span>Avanzando juntos</span>"
                        /> */}
                        <AboutContainer />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AboutPage;
