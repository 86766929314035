import Button from "../button";
import PropTypes from "prop-types";

const Intro = ({ data }) => {
    return (
        <div
            className="intro-section"
            style={{
                backgroundImage: `url(${
                    process.env.PUBLIC_URL + data.backgroundImage
                })`,
                backgroundSize: "100% 100%",
            }}
        >
            {/* <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="slider-content card bg-transparent shadow-lg p-3 mt-5">
                            <p className="text animated delay1">
                                {data.subTitle}
                            </p>
                            <br />
                            <br />
                            <div
                                className=" mt-5"
                                style={{
                                    backgroundColor: "rgb(13 110 253 / 34%)",
                                }}
                            >
                                <br />
                                <br />
                                <h5 className="mb-5 text-white animated delay2">
                                    {data.title}
                                </h5>
                            </div>
                            <Button
                                path={process.env.PUBLIC_URL + "/"}
                                classOption="btn btn-tan me-3 animated delay1"
                                text="Get a quote"
                            />
                            <Button
                                path={process.env.PUBLIC_URL + "/"}
                                classOption="btn btn-outline-secondary animated delay2"
                                text="our services"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
