import React from "react";
import ContactAddress from "../../components/contact-address";
import ContactForm from "../../components/contact-form";
import SectionTitle from "../../components/section-title";
import { useTranslation } from "react-i18next";

const ContactPageContainer = () => {
    const { t } = useTranslation();
    return (
        <section className="contact-area p-5">
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <ContactAddress />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact-form">
                            <SectionTitle
                                classOption="text-center"
                                subTitle={t("contact")}
                                title={`<span> ${t(`we're_alway_ready`).replace(
                                    /_/g,
                                    " "
                                )}</span> ${t("to_help_you").replace(
                                    /_/g,
                                    " "
                                )}`}
                            />
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactPageContainer;
