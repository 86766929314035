import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import first from "../assets/img/ser.jpeg";
import ScrollToTop from "../components/scroll-to-top/index.jsx";
import SectionTitle from "../components/section-title/index.jsx";
import SEO from "../components/seo/index.jsx";
import { axiosInstance } from "../config/https.js";
import CallToAction from "../containers/global/call-to-action/index.jsx";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import ProServiceContainer from "../containers/service-box/proindex.jsx";
import Footer from "../layouts/footer/index.jsx";
import Header from "../layouts/header/index.jsx";
import Layout from "../layouts/index.jsx";

SwiperCore.use([Pagination]);

const FindProfessional = () => {
    const { t } = useTranslation();
    const swiperOption = {
        speed: 600,
        spaceBetween: 30,
        slidesPerView: 3,
        pagination: { clickable: true },
        breakpoints: {
            1200: {
                slidesPerView: 3,
            },

            991: {
                slidesPerView: 2,
            },

            767: {
                slidesPerView: 2,
            },

            560: {
                slidesPerView: 2,
            },

            0: {
                slidesPerView: 1,
            },
        },
    };
    const [loader, setLoader] = useState(false);
    const [professional, setProfessionals] = useState([]);
    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    const getcarehomes = () => {
        setLoader(true);
        axiosInstance
            .get(`user/professionals?user_id=${userDetails.id}`)
            .then((e) => {
                if (e.data.status === false) {
                    toast.error(e.data.error);
                    history.push("/Packages");
                    setLoader(false);
                }
                if (e.data.status === true) {
                    console.log(e);
                    setLoader(false);
                    setProfessionals(e?.data?.data);
                    setFilteredData(e?.data?.data);
                } else {
                    setLoader(false);
                }
            })
            .catch((e) => setLoader(false));
    };
    useEffect(() => {
        getcarehomes();
    }, []);
    return (
        <React.Fragment>
            {loader ? <div className="loader"></div> : null}
            <Layout>
                <SEO title="Avanzando juntos – Service" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <PageTitleContainer
                            image={"/images/33.png"}
                            subTitle={`${t(
                                "best_professionals_for_your_heatlh"
                            ).replace(/_/g, " ")}`}
                            title={`${t("services")} <span>${t(
                                `that_we_provide`
                            ).replace(/_/g, " ")}</span>`}
                        />
                        <ProServiceContainer />
                        <section className=" bg-white mb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <SectionTitle
                                            classOption="text-center"
                                            title={`<span>${t(
                                                `best_professional`
                                            ).replace(/_/g, " ")}</span> ${t(
                                                `for_your_health`
                                            )}`}
                                        />
                                        <div
                                            className="row icon-box-style"
                                            data-aos="fade-up"
                                            data-aos-duration="1100"
                                        >
                                            <Swiper
                                                className="team-slider-container"
                                                {...swiperOption}
                                            >
                                                {professional.length > 0 ? (
                                                    professional
                                                        .filter(
                                                            (s) =>
                                                                s.is_featured ===
                                                                1
                                                        )
                                                        .map((e) => (
                                                            <SwiperSlide>
                                                                <Link
                                                                    to={`/proservice-details/${e.id}`}
                                                                >
                                                                    <img
                                                                        src={
                                                                            e.image
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                            height: 200,
                                                                        }}
                                                                        className="img-thumbnail w-100 "
                                                                    />
                                                                </Link>
                                                            </SwiperSlide>
                                                        ))
                                                ) : (
                                                    <p className="capitalizing">
                                                        {t(
                                                            "no_data_found"
                                                        ).replace(/_/g, " ")}
                                                    </p>
                                                )}
                                            </Swiper>
                                        </div>
                                        <div className="text-center mt-5">
                                            <a
                                                href="https://professional.avanzandojuntos.net/"
                                                target="_blank"
                                                className="btn btn-outline-warning capitalizing p-3 w-50"
                                            >
                                                <i className="icofont-star"></i>{" "}
                                                {t(
                                                    `featured_your_professional`
                                                ).replace(/_/g, " ")}{" "}
                                                <i className="icofont-star"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <TestimonialContainer /> */}
                        {/* <BrandContainer /> */}
                        {/* <PricingContainer pricingClassOption="noborder-line" /> */}
                        <CallToAction />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default FindProfessional;
