import React from "react";

const Google_Ads = (props) => {
    return (
        <img
            src="/branding/sip.gif"
            alt=""
            className="w-100"
            style={{ height: props.height }}
        />
    );
};

export default Google_Ads;
