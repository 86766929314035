import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const MainMenu = () => {
    const { t } = useTranslation();
    const isLogin = localStorage.getItem("accessToken");
    return (
        <nav>
            <ul className="main-menu">
                <li>
                    <NavLink
                        className="main-menu-link"
                        activeClassName="active"
                        exact
                        to="/"
                    >
                        {t("home")}
                    </NavLink>
                </li>
                <li>
                    <NavLink className="main-menu-link" to={"#"}>
                        {t("services")}
                    </NavLink>
                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/service"}
                            >
                                {t("find_nursing_homes").replace(/_/g, " ")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    isLogin
                                        ? process.env.PUBLIC_URL +
                                          "/FindProfressional"
                                        : process.env.PUBLIC_URL + "/login"
                                }
                            >
                                {t("find_professional_care").replace(/_/g, " ")}
                            </NavLink>
                        </li>
                        <li>
                            <a
                                className="sub-menu-link"
                                target="_blank"
                                href="https://professional.avanzandojuntos.net/"
                            >
                                {t("register_professionals").replace(/_/g, " ")}
                            </a>
                        </li>
                        <li>
                            <a
                                className="sub-menu-link"
                                target="_blank"
                                href="https://carehomes.avanzandojuntos.net/"
                            >
                                {t("register_care_homes").replace(/_/g, " ")}
                            </a>
                        </li>
                        <li>
                            <a
                                className="sub-menu-link"
                                target="_blank"
                                href="https://business.avanzandojuntos.net/"
                            >
                                {t("businesses").replace(/_/g, " ")}
                            </a>
                        </li>
                    </ul>
                </li>

                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/blog"}
                    >
                        Blog
                    </NavLink>
                    {/* <ul className="sub-menu">
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/blog"}
                            >
                                Blog list
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/blog-details/1"}
                            >
                                Blog Details
                            </NavLink>
                        </li>
                    </ul> */}
                </li>

                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/about"}
                    >
                        {t("about")}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/Media"}
                    >
                        {t("media")}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/contact"}
                    >
                        {t("contact")}
                    </NavLink>
                </li>
                {isLogin ? (
                    <li>
                        <NavLink className="main-menu-link" to={"#"}>
                            {t("profile")}
                        </NavLink>
                        <ul className="sub-menu">
                            <li>
                                <NavLink
                                    className="sub-menu-link"
                                    to={process.env.PUBLIC_URL + "/Chat"}
                                >
                                    {t("chat")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="sub-menu-link"
                                    to={process.env.PUBLIC_URL + "/booking"}
                                >
                                    {t("my_booking").replace(/_/g, " ")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="sub-menu-link"
                                    to={"/login"}
                                    onClick={() => localStorage.clear()}
                                >
                                    {t("logout")}
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                ) : (
                    <li>
                        <NavLink
                            className="main-menu-link"
                            to={process.env.PUBLIC_URL + "/Login"}
                        >
                            Login
                        </NavLink>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default MainMenu;
