import React from "react";

import { useState } from "react";
import SectionTitle from "../components/section-title";
import { axiosInstance } from "../config/https";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import { useEffect } from "react";

const Booking = () => {
    const [loader, setLoader] = useState(false);
    const [beds, setBeds] = useState([]);
    const getBeds = () => {
        setLoader(true);
        axiosInstance
            .get(`user/bookings`)
            .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setLoader(false);
                    setBeds(response.data.data);
                } else {
                    setLoader(false);
                }
            })
            .catch((errors) => {
                setLoader(false);
                console.log(errors);
            });
    };
    useEffect(() => {
        getBeds();
    }, []);
    return (
        <>
            {loader ? <div className="loader"></div> : null}
            <Header />

            <div className="main-content site-wrapper-reveal container my-3">
                <div className="row">
                    <SectionTitle
                        classOption="text-center"
                        title={`<span>My Booking</span>`}
                    />
                    {beds.length > 0 ? (
                        <>
                            {beds?.map((e) => {
                                return (
                                    <>
                                        <div className="card mb-2">
                                            <div className="row">
                                                <div
                                                    className="col-md-2 text-center my-2 "
                                                    style={{
                                                        borderRight:
                                                            "1px solid black",
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            e?.bed?.floor
                                                                ?.building
                                                                ?.carehome
                                                                ?.image
                                                        }
                                                        width={"100%"}
                                                        height={150}
                                                        style={{
                                                            borderRadius: "50%",
                                                        }}
                                                        alt=""
                                                    />
                                                    <h6>
                                                        {
                                                            e?.bed?.floor
                                                                ?.building
                                                                ?.carehome
                                                                ?.email
                                                        }
                                                    </h6>
                                                </div>
                                                <div className="col-md-10 my-2">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                Care Home name
                                                            </label>
                                                            <h5 className="form-control">
                                                                {
                                                                    e?.bed
                                                                        ?.floor
                                                                        ?.building
                                                                        ?.carehome
                                                                        ?.establishment
                                                                }
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                Build Name
                                                            </label>
                                                            <h5 className="form-control">
                                                                {
                                                                    e.bed.floor
                                                                        .building
                                                                        .title
                                                                }
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                Floor
                                                            </label>
                                                            <h5 className="form-control">
                                                                {
                                                                    e?.bed
                                                                        ?.floor
                                                                        ?.title
                                                                }
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                Bed no
                                                            </label>
                                                            <h5 className="form-control">
                                                                {e?.bed?.title}
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                to{" "}
                                                            </label>
                                                            <h5 className="form-control">
                                                                {e?.start_time}
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                From{" "}
                                                            </label>
                                                            <h5 className="form-control">
                                                                {e?.end_time}
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                StartDate{" "}
                                                            </label>
                                                            <h5 className="form-control">
                                                                {e?.date}
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                End Date{" "}
                                                            </label>
                                                            <h5 className="form-control">
                                                                {e?.end_date}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            <div
                                className="card shadow-lg "
                                style={{ height: "10vh" }}
                            >
                                <h5 className="text-center my-auto">
                                    No Booking Found
                                </h5>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Booking;
