import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const HeaderContactInfo = ({ data }) => {
    const { t } = useTranslation();
    return (
        <li className="media media-list">
            <span className="media-icon">
                <i className={data.icon}></i>
            </span>
            <div className="media-content">
                <span className="media-sub-heading">
                    {t(data.title).replace(/_/g, " ")}
                </span>
                <span className="media-heading">{data.info}</span>
            </div>
        </li>
    );
};

HeaderContactInfo.propTypes = {
    data: PropTypes.object,
};

export default HeaderContactInfo;
