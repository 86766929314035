import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="this is title"
            className="contact-map"
            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Calle padre , Hatillo PR&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
