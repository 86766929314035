import PropTypes from "prop-types";
import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header/index.jsx";
import Breadcrumb from "../containers/global/breadcrumb/index.jsx";
import BlogDetailsContainer from "../containers/blog/blog-details/index.jsx";
import CommentContainer from "../containers/comment-container/index.jsx";
import Footer from "../layouts/footer/index.jsx";
import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top/index.jsx";
import SEO from "../components/seo/index.jsx";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../config/https.js";
import { useEffect } from "react";
import { useState } from "react";
import AdsDetailsContainer from "../containers/ads/blog-details/index.jsx";

const AdsDetailsPage = () => {
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const [getShow, setgetShow] = useState({});
    const Show = () => {
        setLoader(true);
        axiosInstance
            .get(`advertisements/${id}`)
            .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setLoader(false);
                    setgetShow(response.data.data);
                } else {
                    setLoader(false);
                }
            })
            .catch((errors) => {
                setLoader(false);

                console.log(errors);
            });
    };
    useEffect(() => {
        Show();
    }, []);
    return (
        <React.Fragment>
            {loader ? <div className="loader"></div> : null}
            <Layout>
                <SEO title="Avanzando juntos – Blog Details" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <Breadcrumb
                            prevs={[
                                { text: "Home", path: "/" },
                                { text: "Advertisment", path: "/" },
                            ]}
                            contentThree={getShow?.title}
                        />
                        <AdsDetailsContainer data={getShow} />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

AdsDetailsPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default AdsDetailsPage;
