import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import SocialIcon from "../../social-icon";
import Logo from "../../logo";

const MobileMenu = ({ show, onClose }) => {
    console.log(show, "<=====Index");
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    const isLogin = localStorage.getItem("accessToken");

    return (
        <>
            <div
                className={`offcanvas offcanvas-mobile-menu ${
                    show ? "offcanvas-open" : ""
                }`}
                style={{
                    visibility: show ? "visible" : "hidden",
                    background: "#4990e1",
                }}
            >
                <div className="inner">
                    <div className="border-bottom mb-3 pb-3 text-end">
                        <button className="offcanvas-close" onClick={onClose}>
                            ×
                        </button>
                    </div>
                    <div className="offcanvas-head mb-3">
                        <div className="header-top-offcanvas">
                            <Logo
                                image={`${process.env.PUBLIC_URL}/img/logo.png`}
                            />
                        </div>
                    </div>
                    <nav className="offcanvas-menu">
                        <ul>
                            <li>
                                <NavLink
                                    exact
                                    to={process.env.PUBLIC_URL + "/"}
                                >
                                    <span className="menu-text text-white ">
                                        Home
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"#"}>
                                    <span className="menu-text text-white">
                                        Services
                                    </span>
                                </NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul className="offcanvas-submenu">
                                    <li>
                                        <NavLink
                                            className="sub-menu-link text-white"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service"
                                            }
                                        >
                                            Find Nursing Homes
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link text-white"
                                            to={
                                                isLogin
                                                    ? process.env.PUBLIC_URL +
                                                      "/FindProfressional"
                                                    : process.env.PUBLIC_URL +
                                                      "/login"
                                            }
                                        >
                                            Find Professional Care
                                        </NavLink>
                                    </li>
                                    <li>
                                        <a
                                            className="sub-menu-link text-white"
                                            target="_blank"
                                            href="https://professional.avanzandojuntos.net/"
                                        >
                                            Register Professionals
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="sub-menu-link text-white"
                                            target="_blank"
                                            href="https://carehomes.avanzandojuntos.net/"
                                        >
                                            Register Care Homes
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="sub-menu-link text-white"
                                            target="_blank"
                                            href="https://business.avanzandojuntos.net/"
                                        >
                                            Businesses
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <NavLink to={process.env.PUBLIC_URL + "/blog"}>
                                    <span className="menu-text text-white">
                                        blog
                                    </span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to={process.env.PUBLIC_URL + "/about"}>
                                    <span className="menu-text text-white">
                                        about
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={process.env.PUBLIC_URL + "/Media"}>
                                    <span className="menu-text text-white">
                                        Media
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/contact"}
                                >
                                    <span className="menu-text text-white">
                                        Contact Us
                                    </span>
                                </NavLink>
                            </li>
                            {isLogin ? (
                                <li>
                                    <NavLink to={"#"}>
                                        <span className="menu-text text-white">
                                            Profile
                                        </span>
                                    </NavLink>
                                    <span
                                        className="menu-expand"
                                        onClick={onClickHandler}
                                        aria-hidden="true"
                                    ></span>
                                    <ul className="offcanvas-submenu">
                                        <li>
                                            <NavLink
                                                className="sub-menu-link text-white"
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/Chat"
                                                }
                                            >
                                                Chat{" "}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className="sub-menu-link text-white"
                                                to={"/booking"}
                                            >
                                                My Booking
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className="sub-menu-link text-white"
                                                to={"/login"}
                                                onClick={() =>
                                                    localStorage.clear()
                                                }
                                            >
                                                LogOut
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a
                                                className="sub-menu-link text-white"
                                                target="_blank"
                                                href="https://carehomes.avanzandojuntos.net/"
                                            >
                                                Register Care Homes
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="sub-menu-link text-white"
                                                target="_blank"
                                                href="https://business.avanzandojuntos.net/"
                                            >
                                                Businesses
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/Login"}
                                    >
                                        Login
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                    <div className="offcanvas-social my-4">
                        <ul>
                            <li>
                                <SocialIcon
                                    path="https://www.facebook.com/"
                                    icon="icofont-facebook"
                                />
                            </li>
                            <li>
                                <SocialIcon
                                    path="https://www.instagram.com/"
                                    icon="icofont-instagram"
                                />
                            </li>
                        </ul>
                    </div>

                    <ul className="media-wrap">
                        <li className="media media-list">
                            <span className="media-icon text-white">
                                <i className="icofont-clock-time"></i>
                            </span>
                            <div className="media-content">
                                <span className="media-sub-heading text-white">
                                    working hours
                                </span>
                                <span className="media-heading text-white">
                                    MON - FRI: 8AM - 4PM{" "}
                                </span>
                            </div>
                        </li>

                        <li className="media media-list">
                            <span className="media-icon text-white">
                                <i className="icofont-ui-call"></i>
                            </span>
                            <div className="media-content">
                                <span className="media-sub-heading text-white">
                                    hotline 24/7
                                </span>
                                <a
                                    className="media-heading text-white"
                                    href="tel:+0962-58-58-258"
                                >
                                    939-246-0206{" "}
                                </a>
                            </div>
                        </li>

                        <li className="media media-list">
                            <span className="media-icon text-white">
                                <i className="icofont-envelope"></i>
                            </span>
                            <div className="media-content">
                                <span className="media-sub-heading text-white">
                                    email us
                                </span>
                                <a
                                    style={{ fontSize: 13 }}
                                    className="media-heading text-white "
                                    href="mailto:support@clenora.com.uk"
                                >
                                    AVANZANDOJUNTOS.PR@GMAIL.COM{" "}
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
