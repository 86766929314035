import React, { useEffect, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionTitle from "../components/section-title";
import SEO from "../components/seo";
import HomeData from "../data/home.json";
import Layout from "../layouts";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../config/https";

SwiperCore.use([Navigation]);

const Media = () => {
    const { t, i18n } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [Data, setData] = useState([]);
    const video = [
        {
            name: "Avanzando Juntos User",
            src: "/tutorial/Avanzando_user.mp4",
        },
        {
            name: "Avanzando Juntos Professional",
            src: "/tutorial/Avanzando_professional.mp4",
        },
        {
            name: "Avanzando Juntos Carehome",

            src: "/tutorial/Avanzando_carehome.mp4",
        },
    ];
    const getMedia = () => {
        setLoader(true);
        axiosInstance
            .get(`portfolios`)
            .then((response) => {
                const { status, data } = response.data;
                if (status) {
                    setData(data);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((err) => {
                setLoader(false);
            });
    };
    useEffect(() => {
        getMedia();
    }, []);

    const swiperOption = {
        loop: true,
        speed: 600,
        spaceBetween: 0,
        slidesPerView: 1,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".hero-slider .swiper-button-next",
            prevEl: ".hero-slider .swiper-button-prev",
        },
    };
    const swiperOption2 = {
        loop: true,
        speed: 600,
        spaceBetween: 30,
        slidesPerView: 3,
        pagination: { clickable: true },
        breakpoints: {
            1200: {
                slidesPerView: 3,
            },

            991: {
                slidesPerView: 2,
            },

            767: {
                slidesPerView: 2,
            },

            560: {
                slidesPerView: 2,
            },

            0: {
                slidesPerView: 1,
            },
        },
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Avanzando juntos – " />
                <div className="wrapper home-default-wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <div className="hero-slider-area">
                            <Swiper
                                effect="fade"
                                className="hero-slider"
                                {...swiperOption}
                            >
                                {HomeData[9].media &&
                                    HomeData[9].media.map((single, key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                <div
                                                    className="intro-section"
                                                    style={{
                                                        backgroundImage: `url(${
                                                            process.env
                                                                .PUBLIC_URL +
                                                            single?.backgroundImage
                                                        })`,
                                                        backgroundSize:
                                                            "100% 100%",
                                                    }}
                                                ></div>
                                            </SwiperSlide>
                                        );
                                    })}
                                <div className="swiper-button-prev">
                                    <i className="icofont-arrow-left"></i>
                                </div>
                                <div className="swiper-button-next">
                                    <i className="icofont-arrow-right"></i>
                                </div>
                            </Swiper>
                        </div>
                        {/* <TeamContainer /> */}
                        <div className="team-area team-default-area bg-white p-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            classOption="text-center"
                                            title={`<span>Avanzando Juntos</span> ${t(
                                                `tutorials_video`
                                            ).replace(/_/g, " ")}`}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    {video.map((video) => (
                                        <div
                                            className="col-lg-4 col-sm-12"
                                            data-aos="fade-up"
                                            data-aos-duration="1300"
                                        >
                                            <div className="video-wrapper">
                                                <video
                                                    controls
                                                    className="w-100"
                                                    src={video.src}
                                                />
                                            </div>
                                            <div className="video-caption text-center mt-1 fs-4">
                                                {t(`${video.name}`)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="team-area team-default-area bg-white p-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            classOption="text-center"
                                            title={`<span>Avanzando Juntos </span> ${t(
                                                `video_portfolio`
                                            ).replace(/_/g, " ")}`}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="col-lg-12"
                                        data-aos="fade-up"
                                        data-aos-duration="1300"
                                    >
                                        <div>
                                            <div className="container">
                                                <div className="row">
                                                    {Data.map((e, i) => {
                                                        const isEven =
                                                            i % 2 === 0;
                                                        const imgClass = isEven
                                                            ? "order-md-first"
                                                            : "order-md-last";
                                                        const descriptionClass =
                                                            isEven
                                                                ? "order-md-last"
                                                                : "order-md-first";

                                                        return (
                                                            <div className="row my-3">
                                                                <div
                                                                    className={`col-md-6 my-3 ${imgClass}`}
                                                                >
                                                                    <video
                                                                        autoPlay
                                                                        loop
                                                                        controls
                                                                        muted
                                                                        src={
                                                                            e.media
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                            height: 273,
                                                                        }}
                                                                        className="w-100 text-center img-thumbnail"
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={`col-md-6 my-3 ${descriptionClass} my-auto`}
                                                                >
                                                                    <h4 className="capitalizing new_text new text-center">
                                                                        {
                                                                            e.description
                                                                        }
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Layout>
            <style>{`
            @import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@700&display=swap');
            .new_text{color:#004AAD}
            .new{font-family: 'Cormorant', serif;}
            `}</style>
        </React.Fragment>
    );
};

export default Media;
