import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CallToAction = () => {
    const { t } = useTranslation();

    return (
        <div
            className="divider-area bgcolor-theme bg-img"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/img/shape/01.jpg)`,
            }}
        >
            <div className="container">
                <div className="row content-align-center">
                    <div className="col-lg-12">
                        <div
                            className="divider-content-area divider-content-style1"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <div className="content-inner">
                                <h2 className="capitalizing">
                                    {t(`book_your`).replace(/_/g, " ")}
                                    &nbsp;
                                    <span className="text-capitalize">
                                        {t(`appointment_today`).replace(/_/, " ")}
                                    </span>
                                </h2>
                                <p className="capitalizing">
                                    {t(
                                        `we_provide_assistance_with_any_doubts_or_questions._we_are_at_your_service.`
                                    ).replace(/_/g, " ")}
                                </p>
                            </div>
                            <Link to="/" className="btn btn-theme btn-white">
                                {t("reservation").replace(/_/g, " ")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallToAction;
