import React, { useState, useEffect } from "react";
import "./Timer.css";
import { Link, useHistory } from "react-router-dom";

const Success = () => {
    const [seconds, setSeconds] = useState(5);
    const history = useHistory();

    useEffect(() => {
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        if (seconds === 0) {
            clearInterval(timer);
            history.push("/");
        }

        return () => clearInterval(timer);
    }, [seconds]);

    const calculateBorderSize = () => {
        const maxSize = 10; // Maximum border size in pixels
        const remainingSeconds = seconds > 0 ? seconds : 0;
        const percentage = (remainingSeconds / 30) * 100; // Calculate percentage of remaining time

        return Math.round((percentage / 100) * maxSize);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4" />
                <div className="col-md-4">
                    <div className="timer-container ">
                        <div className="card bg-success p-3">
                            <h5 className="text-white">
                                Payment SuccessFully Done You Will be Redirect
                                to Home page in {seconds}sec
                            </h5>
                            <div
                                className="timer "
                                style={{
                                    // border: `${calculateBorderSize()}px solid `,
                                    color: "white",
                                }}
                            >
                                {seconds}s
                            </div>
                            <Link to={"/"} className="btn btn-outline-dark mt-">
                                Go Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;
