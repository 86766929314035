import React from "react";
import { useTranslation } from "react-i18next";
import TeamContainer from "../home/team";
import WhyAvan from "../whyAvan";

const AboutContainer = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="bg-light">
            <div className="about-area p-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">
                            <img
                                src="/img/rounded.png"
                                alt=""
                                className="img-fluid "
                            />
                        </div>
                        <div className="col-md-10">
                            <div
                                className="mb-3"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <h4 className="capitalizing mb-2 underlinestyle">
                                    {t(`mission`).replace(/_/g, " ")}:
                                </h4>
                                <p className="capitalizing home-text">
                                    {t(
                                        `the_mission_of_avanzando_juntos_corporation_is_to_bridge_the_gap_between_healthcare_\r\nproviders_and_patient_families_by_facilitating_the_care_search_process._our_goal_is_to_\r\nraise_awareness_about_the_importance_of_transparent_and_dignified_care,_while_aspiring_\r\nto_become_a_reliable_base_of_resources_to_meet_the_needs_of_homes,_hospitals,_and_\r\nother_care_centers.`
                                    ).replace(/_/g, " ")}
                                </p>
                            </div>
                            <div
                                className="mb-3"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <h4 className="capitalizing mb-2 underlinestyle">
                                    {t(`vision`).replace(/_/g, " ")}:
                                </h4>
                                <p className="capitalizing home-text">
                                    {t(
                                        `the_vision_of_avanzando_juntos_corporation_is_to_become_the_number_one_source_of_\r\nexcellent_and_transparent_healthcare_providers_in_puerto_rico._we_strive_to_drive_\r\nprogress_in_advocating_for_care_and_make_significant_changes_to_ensure_the_highest_level_\r\nof_holistic_care_for_everyone._additionally,_we_aim_to_become_a_leading_platform_that_\r\nprovides_reliable_information_and_the_largest_resource_bank_of_professionals_to_meet_the_\r\nneeds_of_homes,_hospitals,_and_other_care_centers._we_are_an_equal_opportunity_\r\nemployer_committed_to_fostering_a_diverse_and_inclusive_workplace.`
                                    ).replace(/_/g, " ")}
                                </p>
                            </div>
                            <div
                                className="mb-3"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <h4 className="capitalizing mb-2 underlinestyle">
                                    {t(`our_values`).replace(/_/g, " ")}:
                                </h4>
                                <ul className="mt-3">
                                    {" "}
                                    <li
                                        style={{
                                            listStyle: "unset",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        <p className="capitalizing home-text">
                                            <span className="fw-bold">
                                                {t(`transparency`).replace(
                                                    /_/g,
                                                    " "
                                                )}{" "}
                                            </span>{" "}
                                            –
                                            {t(
                                                `we_believe_every_patient_deserves_the_highest_quality_care,_especially_\r\nin_their_most_vulnerable_state._our_goal_is_to_provide_comprehensive_information_to_\r\nhelp_families_make_informed_decisions.`
                                            ).replace(/_/g, " ")}
                                        </p>
                                    </li>
                                    <li
                                        style={{
                                            listStyle: "unset",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        <p className="capitalizing home-text">
                                            <span className="fw-bold">
                                                {t(
                                                    `independence_and_impartiality`
                                                ).replace(/_/g, " ")}
                                            </span>
                                            –
                                            {t(
                                                `we_strive_to_earn_your_trust_by_providing_updated_\r\nand_organized_information,_giving_you_access_to_everything_you_need_in_one_place._\r\nfacts_without_biases_or_undue_influences_will_always_be_our_priority.`
                                            )}
                                        </p>
                                    </li>
                                    <li
                                        style={{
                                            listStyle: "unset",
                                            marginBottom: "2rem",
                                        }}
                                    >
                                        <p className="capitalizing home-text">
                                            <span className="fw-bold">
                                                {t(`respect`).replace(
                                                    /_/g,
                                                    " "
                                                )}
                                            </span>
                                            : –
                                            {t(
                                                `we_value_respect_in_every_aspect,_regardless_of_age,_race,_or_nationality._\r\neveryone_deserves_compassionate,_dignified,_and_fair_treatment.`
                                            ).replace(/_/g, " ")}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div
                            className="my-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h1
                                className="text-center fw-light home-heading my-3"
                                style={{ fontSize: 50 }}
                            >
                                {t("need__help?_").replace(/_/g, " ")}
                            </h1>
                            <br />
                            <h6 className="text-center fw-light home-text mb-3">
                                {t(
                                    "we_have_added_the_definitions_of_each_type_of_nursing_care_to_help_you_decide_the_right_fit_or_your_loved_one._if_you_need_further_assistance,_contact_us."
                                ).replace(/_/g, " ")}
                            </h6>
                            <p className="text-center home-text">
                                {t(
                                    "we_are_here_to_help_simplify_your_search._if_you_need_further_assistanc,_you_can_contact_us_via_the_chat_icon."
                                ).replace(/_/g, " ")}
                            </p>
                        </div>
                        <div
                            className="my-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="text-center capitalizing fw-light home-text">
                                {/* {t(
                                    `law_no._94_of_june_22,_1977,_p._94,_as_amended_(disclaimer:_we_have_not_added_complete_definition,_just_small_summary_of_each._to_read_full_definition,_please_click_on_link_found_below._eclac`
                                ).replace(/_/g, " ")} */}
                                Ley de Establecimientos Para Personas de Edad
                                Avanzada Ley Núm. 94 de 22 de junio de 1977,
                                p.94, segun enmendada Art. 3. Definiciones (8
                                L.P.R.A. sec. 353)
                            </p>
                            <h6 className="text-center text-danger home-text">
                                {/* {t(`disclaimer`).replace(/_/g, " ")}
                                {t(`disclaimer`).replace(/_/g, " ")} */}
                                Descargo de responsabilidad: La información
                                proporcionada en esta plataforma es para fines
                                informativos. fines únicamente. Se anima a los
                                usuarios a verificar la exactitud y
                                confiabilidad de la información antes de tomar
                                cualquier acción o decisión basada en ella.
                                Tenga en cuenta que la información proporcionada
                                puede no ser exacta y está sujeta a
                                verificación. Hacer clic en el enlace de la
                                CEPAL a continuaciónpara acceder a la definición
                                completa y obtener una visión comprensión.
                            </h6>
                        </div>
                        <div
                            className="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="capitalizing home-text">
                                <span>
                                    <b>
                                        <i>
                                            {t(`institution`).replace(
                                                /_/g,
                                                " "
                                            )}
                                            :
                                        </i>
                                    </b>
                                </span>{" "}
                                {t(
                                    `means_any_home,_institute,_residential_facility,_shelter,_annex,_center,_\r\nhome,_house,_mission,_or_refuge_dedicated_to_the_care_of_seven_(7)_elderly_\r\npersons_or_more,_24_hours_a_day,_with_or_without_pecuniary_aims.`
                                ).replace(/_/g, " ")}
                            </p>
                        </div>
                        <div
                            className="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="capitalizing home-text">
                                <span>
                                    <b>
                                        <i>{t(`day_care_home`)}:</i>
                                    </b>
                                </span>{" "}
                                {t(
                                    `it_is_the_home_of_a_family_that,_for_payment,_dedicates_itself_\r\nregularly_to_the_daytime_care_of_a_maximum_of_six_(6)_adults_unrelated_by_blood_\r\nto_said_family.`
                                ).replace(/_/g, " ")}{" "}
                            </p>
                        </div>
                        <div
                            className="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="capitalizing home-text">
                                <span>
                                    <b>
                                        <i>{t(`foster_home`)}:</i>
                                    </b>
                                </span>{" "}
                                {t(
                                    `it_is_the_home_of_a_family_dedicated_to_the_care,_with_or_without_\r\npecuniary_aims,_of_no_more_than_six_(6)_elderly_persons_from_other_homes_or_\r\nfamilies,_24_hours_a_day.`
                                ).replace(/_/g, " ")}
                            </p>
                        </div>
                        <div
                            className="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="capitalizing home-text">
                                <span>
                                    <b>
                                        <i>{t(`day_care_center`)}:</i>
                                    </b>
                                </span>{" "}
                                {t(
                                    `means_an_establishment,_with_or_without_pecuniary_aims,_\r\nwhere_elderly_persons_are_provided_a_series_of_mostly_health_services_for_persons_\r\nwith_more_than_three_limitations_of_daily_living.`
                                ).replace(/_/g, " ")}{" "}
                            </p>
                        </div>
                        <div
                            className="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="capitalizing home-text">
                                <span>
                                    <b>
                                        <i>
                                            {t(
                                                `multiple_activity_center`
                                            ).replace(/_/g, " ")}
                                            :
                                        </i>
                                    </b>
                                </span>{" "}
                                {t(
                                    `means_an_establishment,_with_or_without_\r\npecuniary_aims,_where_elderly_persons_are_provided_a_series_of_mostly_social_and_\r\nrecreational_services,_with_the_purpose_of_maintaining_or_maximizing_their_\r\nindependence_during_part_of_the_twenty-four_(24)_hours_of_the_day.`
                                ).replace(/_/g, " ")}
                            </p>
                        </div>
                        <div
                            className="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="capitalizing home-text">
                                <span>
                                    <b>
                                        <i>
                                            {t(`elderly_person`).replace(
                                                /_/g,
                                                " "
                                            )}
                                            :
                                        </i>
                                    </b>
                                </span>{" "}
                                {t(
                                    `means_a_human_being_aged_60_years_or_older.`
                                ).replace(/_/g, " ")}
                            </p>
                        </div>
                        <div
                            className="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="capitalizing home-text">
                                <span>
                                    <b>
                                        <i>
                                            {t(`license`).replace(/_/g, " ")}:
                                        </i>
                                    </b>
                                </span>{" "}
                                {t(
                                    `means_a_written_permit_issued_by_the_department_authorizing_a_natural_\r\nor_legal_person_to_operate_an_institution,_daycare_center,_foster_home,_or_daycare_\r\nhome.`
                                ).replace(/_/g, " ")}
                            </p>
                        </div>
                        <div
                            className="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <p className="capitalizing home-text">
                                <span>
                                    <b>
                                        <i>
                                            {t(`establishment`).replace(
                                                /_/g,
                                                " "
                                            )}
                                            :
                                        </i>
                                    </b>
                                </span>{" "}
                                {t(
                                    `includes_all_institution,_daycare_center,_multipurpose_\r\nactivities_center,_foster_home,_daycare_home,_as_defined_in_this_article.`
                                ).replace(/_/g, " ")}
                            </p>
                        </div>
                        <div
                            className="my-3"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h1
                                className="text-center capitalizing fw-light home-heading"
                                style={{ fontSize: 50 }}
                            >
                                {t("corporation").replace(/_/g, " ")}
                            </h1>
                            <br />
                            <h6 className="text-center fw-light home-text">
                                {t(
                                    `our_ceo,_a_registered_nurse_with_a_master's_degree_in_gerontology_and_many_years_\r\nof_experience_in_providing_excellent_care,_understands_the_needs_and_standards_for_\r\nyour_loved_ones._our_team_adheres_to_the_highest_ethical_standards._we_understand_\r\nthat_we_must_earn_the_public's_trust._we_work_diligently_every_day_to_provide_the_\r\nhighest_level_of_service._please_utilize_the_following_resource_links_to_educate_\r\nyourself_about_your_rights_and_benefits_that_you_may_be_entitled_to.`
                                ).replace(/_/g, " ")}
                            </h6>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <TeamContainer />
            <WhyAvan />
            <div data-aos="fade-up" data-aos-duration="1200" className="my-5">
                <h1
                    className="text-center my-3 capitalizing fw-light home-heading"
                    style={{ fontSize: 50 }}
                >
                    {t("resources").replace(/_/g, " ")}
                </h1>
                <h6 className="home-text fw-light text-center capitalizing mb-3">
                    {t(
                        `links_of_public_please_click_on_any_link_of_interest_to_be_redirected_to_the_corresponding_page_information_related_to_platform`
                    ).replace(/_/g, " ")}
                    :
                </h6>
                <p className="text-center mb-3">
                    <a
                        className={"text-dark home-text text-center "}
                        href="https://agencias.pr.gov/agencias/oppea/Pages/default.aspx"
                    >
                        {t(`oppea`).replace(/_/g, " ")}:
                        <br />
                        https://agencias.pr.gov/agencias/oppea/Pages/default.aspx
                    </a>
                </p>
                <p className="text-center mb-3">
                    <a
                        className={"text-dark home-text text-center "}
                        href="https://adfan.pr.gov/AdministracionesAuxiliares/ServiciosEdadAvanzada/Pages/default.aspx"
                    >
                        {t(`anfan`).replace(/_/g, " ")}:
                        <br />
                        https://adfan.pr.gov/AdministracionesAuxiliares/ServiciosEdadAvanzada/Pages/default.aspx
                    </a>
                </p>
                <p className="text-center mb-3">
                    <a
                        className={"text-dark home-text text-center "}
                        href="https://www.benefits.gov/es/news/article/437"
                    >
                        {t(`benefits.gov`).replace(/_/g, " ")}:
                        <br />
                        https://www.benefits.gov/es/news/article/437
                    </a>
                </p>
                <p className="text-center mb-3">
                    <a
                        className={"text-dark home-text text-center "}
                        href="https://ayudalegalpr.org/resource/derecho-a-recibir-pensin-alimentaria-personas"
                    >
                        {t(`AYUDA LEGAL PR`).replace(/_/g, " ")}
                        :
                        <br />
                        https://ayudalegalpr.org/resource/derecho-a-recibir-pensin-alimentaria-personas
                    </a>
                </p>
                <p className="text-center mb-3">
                    <a
                        className={"text-dark home-text text-center "}
                        href="https://sanjuan.pr/servicios/desarrollo-social-comunitario/"
                    >
                        {t(`dept._desarollo_social_comunitario`).replace(
                            /_/g,
                            " "
                        )}
                        :
                        <br />
                        https://sanjuan.pr/servicios/desarrollo-social-comunitario/
                    </a>
                </p>
                <p className="text-center mb-3">
                    <a
                        className={"text-dark home-text text-center "}
                        href="https://oig.cepal.org/sites/default/files/2019_ley121_pri.pdf"
                    >
                        {t(`ley_núm.`).replace(/_/g, " ")} 121, 2019:
                        <br />
                        https://oig.cepal.org/sites/default/files/2019_ley121_pri.pdf
                    </a>
                </p>
                <p className="text-center">
                    <a
                        className={"text-dark home-text text-center "}
                        href="https://oig.cepal.org/sites/default/files/1977_ley94_pri.pdf"
                    >
                        {t(`cepal`).replace(/_/g, " ")}:
                        <br />
                        https://oig.cepal.org/sites/default/files/1977_ley94_pri.pdf
                    </a>
                </p>
            </div>
        </div>
    );
};

export default AboutContainer;
