import React from "react";
import { useTranslation } from "react-i18next";

const WhyAvan = () => {
    const { t, i18n } = useTranslation();
    const Data = [
        {
            imghead: "nursing_home_search_engine",
            description: `Esta herramienta GRATUITA y de uso sencillo está diseñada para asistirle en la 
búsqueda de hogares de ancianos cercanos a usted, facilitando su proceso de 
búsqueda y permitiéndole establecer conexiones directas con dichos 
establecimientos.`,
        },
        {
            imghead: "_economical_and_accessible",
            description:
                "while_certain_tools_are_free,_we_also_offer_numerous_other_features_that_can_assist_\r\nyou_across_various_areas_at_a_low_cost._register_to_find_healthcare_providers,_apply_\r\nfor_caregiver_jobs,_check_bed_availability_in_nursing_homes,_create_profiles,_and_\r\ndirectly_contact_providers.",
        },
        {
            imghead: "easy_to_use_technology",
            description:
                "our_tech_team_has_created_this_website_with_you_in_mind._you_can_contact_us_directly_for_any_questions._we_want_to_facilitate_an_already_difficult_task_by_helping_you_search_for_care_and_make_informed_decisions_.",
        },
        {
            imghead: "caregiver_search",
            description:
                "are_database_for_caregivers_is_just_starting,_soon_you’ll_be_able_to_search_for_caregivers_in_your_area._we_understand_that_both_ends_need_flexibility_for_various_reasons,_that’s_why_you_choose_your_own_schedule,_hours_and_negociate_your_own_prices.",
        },
    ];
    return (
        <>
            <div style={{ backgroundColor: "#C3D1E0" }}>
                <div className="container">
                    <div className="row">
                        <h1 className="capitalizing new_text new text-center my-3">
                            {" "}
                            {t(`why_choose_avanzando_juntos`).replace(
                                /"_"/g,
                                " "
                            )}
                            ?{" "}
                        </h1>
                        {Data.map((e, i) => {
                            const isEven = i % 2 === 0;
                            const imgClass = isEven
                                ? "order-md-first"
                                : "order-md-last";
                            const descriptionClass = isEven
                                ? "order-md-last"
                                : "order-md-first";

                            return (
                                <div className="row my-3">
                                    <div
                                        className={`col-md-6 my-3 ${imgClass}`}
                                    >
                                        <h3 className="capitalizing new_text new text-center">
                                            {t(e.imghead).replace(/_/g, " ")}
                                        </h3>
                                        <img
                                            src={`/img/${i}.png`}
                                            alt=""
                                            className="w-100 text-center img-thumbnail"
                                        />
                                    </div>
                                    <div
                                        className={`col-md-6 my-3 ${descriptionClass} my-auto`}
                                    >
                                        <h4 className="capitalizing new_text home-text fw-light text-center text-captilaize">
                                            {t(e.description).replace(
                                                /_/g,
                                                " "
                                            )}
                                        </h4>
                                    </div>
                                </div>
                            );
                        })}
                        <h1 className="capitalizing new_text new text-center my-3 text-captilaize">
                            {" "}
                            {t(
                                `we_have_many_new_features_coming_soon!`
                            ).replace(/_/g, " ")}
                        </h1>
                    </div>
                    <p className="text-captilaize text-danger">
                        {t("disclaimer")}:{" "}
                        {t(
                            `avanzando_juntos_corporation_does_not_employ_or_recommend_any_care_provider._avanzando_juntos_corporation_provides_technology_and_tools_to_help_families_and_caregivers_connect_with_each_other._each_individual_is_solely_responsible_for_selecting_a_care_provider_or_care_seeker,_whichever_applies,_and_complying_with_local_and_federal_laws_in_connection_with_a_business_relationship_they_create.`
                        ).replace(/_/g, " ")}
                        <br />
                        <br />
                    </p>
                </div>
            </div>
            <style>{`
            @import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@700&display=swap');
            .new_text{color:#004AAD}
            .new{font-family: 'Cormorant', serif;}
            `}</style>
        </>
    );
};

export default WhyAvan;
