import React, { useEffect, useState } from "react";
import SectionTitle from "../../components/section-title";
import ServiceItem from "../../components/service-item";
import { axiosInstance } from "../../config/https";
import { useTranslation } from "react-i18next";

const ServiceContainer = () => {
    const [loader, setLoader] = useState(false);
    const [carehomes, setCarehomes] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const getcarehomes = () => {
        setLoader(true);
        axiosInstance
            .get("carehomes")
            .then((response) => {
                const Data = response.data.data;
                if (response.data.status === true) {
                    setCarehomes(Data);
                    setFilteredData(Data);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((e) => {
                setLoader(false);
                console.log(e, "<======error");
            });
    };
    useEffect(() => {
        getcarehomes();
    }, []);

    const searchData = (searchInput) => {
        if (searchInput !== "") {
            const filtered = carehomes.filter(
                (item) =>
                    item.establishment
                        ?.toLowerCase()
                        ?.includes(searchInput.toLowerCase()) ||
                    false ||
                    item.address
                        ?.toLowerCase()
                        ?.includes(searchInput.toLowerCase()) ||
                    false ||
                    item.email
                        ?.toLowerCase()
                        ?.includes(searchInput.toLowerCase()) ||
                    false
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(carehomes);
        }
    };

    const handleSearchInput = (event) => {
        searchData(event.target.value);
    };

    const { t } = useTranslation();

    return (
        <>
            {loader ? <div className="loader"></div> : null}
            <div className="">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <input
                                placeholder="Search here...."
                                type="search"
                                className="form-control mb-3"
                                onChange={handleSearchInput}
                            />
                            <div className="row service-style2">
                                {filteredData.length > 0 ? (
                                    filteredData
                                        .slice(0, 8)
                                        .map((single, key) => (
                                            <ServiceItem
                                                id={single.id}
                                                key={key}
                                                img={single?.image}
                                                title={single.establishment}
                                                address={single.address}
                                                email={single.email}
                                            />
                                        ))
                                ) : (
                                    <>{t("no_data_found").replace(/_/g, " ")}</>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceContainer;
