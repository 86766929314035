import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ProServiceItem = (props) => {
    return (
        <div className="col-sm-6 col-md-6 col-lg-3 service-item">
            <Link to={`/proservice-details/${props.id}`}>
                <div className="thumb">
                    <img
                        width={242}
                        height={242}
                        src={props?.img}
                        alt={props?.img}
                        className="img-thumbnail"
                    />
                </div>
                <div className="content">
                    <h5 className="service-name mt-2">{props?.title}</h5>
                    <span className="text-dark text-capitalzie">
                        {props?.address}
                    </span>
                    <br />
                    <span className="text-dark">{props?.email}</span>
                    {/* <Link
                    className="btn-link"
                    to={
                        process.env.PUBLIC_URL + `/service-details/${props?.id}`
                    }
                >
                    More <i className="icofont-simple-right"></i>
                </Link> */}
                </div>
            </Link>
        </div>
    );
};

ProServiceItem.propTypes = {
    data: PropTypes.object,
};

export default ProServiceItem;
