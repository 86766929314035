import React from "react";
import { useForm } from "react-hook-form";
import { axiosInstance } from "../../config/https";
import { useState } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");
    const onChange = (setState) => (e) => {
        setState(e.target.value);
    };
    const contact = (e) => {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append("name", name);
        formdata.append("subject", subject);
        formdata.append("email", email);
        formdata.append("text", text);
        axiosInstance
            .post("contact_mail", formdata)
            .then((response) => {
                if (response.data.status === true) {
                    toast.success(response.data.response);
                    setName("");
                    setSubject("");
                    setEmail("");
                    setText("");
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const { register, errors } = useForm({
        mode: "onBlur",
    });
    return (
        <form
            className="contact-form-wrapper"
            data-aos="fade-up"
            data-aos-duration="1200"
            onSubmit={contact}
        >
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            value={name}
                            onChange={onChange(setName)}
                            placeholder={t("your_name")}
                            ref={register({ required: "Name is required" })}
                        />
                        {errors.name && <p>{errors.name.message}</p>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="email"
                            name="email"
                            value={email}
                            onChange={onChange(setEmail)}
                            placeholder={t("email")}
                            ref={register({
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address",
                                },
                            })}
                        />
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            value={subject}
                            onChange={onChange(setSubject)}
                            name="subject"
                            placeholder={`${t("subject")}(${t("optional")})`}
                            ref={register({
                                required: "Subject is required",
                            })}
                        />
                        {errors.subject && <p>{errors.subject.message}</p>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group mb-0">
                        <textarea
                            name="message"
                            rows="5"
                            value={text}
                            onChange={onChange(setText)}
                            placeholder={t("write_your_message_here")}
                            ref={register({
                                required: "Message is required",
                            })}
                        ></textarea>
                        {errors.message && <p>{errors.message.message}</p>}
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    <div className="form-group mb-0">
                        <button
                            className="btn btn-theme btn-block"
                            type="submit"
                        >
                            {t("send_message")}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
