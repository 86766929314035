import { Link } from "react-router-dom";
import SidebarTitle from "../sidebar-title";

const ProSidebarWorkingTime = ({ data }) => {
    console.log(data, "new data");
    return (
        <div className="widget-item">
            <div className="widget-work-hours">
                <SidebarTitle classOption="title" title="Work Hours" />
                <ul>
                    {data?.slots?.length !== 0 &&
                        data?.slots?.map((e) => {
                            const time = e.start_time;
                            const endtime = e.end_time;

                            const convertTo12HourFormat = () => {
                                const timeParts = time.split(":");
                                let hour = parseInt(timeParts[0], 10);
                                const minute = timeParts[1];

                                let period = "AM";

                                if (hour === 0) {
                                    hour = 12;
                                } else if (hour >= 12) {
                                    period = "PM";
                                    if (hour > 12) {
                                        hour -= 12;
                                    }
                                }

                                return `${hour}:${minute} ${period}`;
                            };
                            const endconvertTo12HourFormat = () => {
                                const timeParts = endtime.split(":");
                                let hour = parseInt(timeParts[0], 10);
                                const minute = timeParts[1];

                                let period = "AM";

                                if (hour === 0) {
                                    hour = 12;
                                } else if (hour >= 12) {
                                    period = "PM";
                                    if (hour > 12) {
                                        hour -= 12;
                                    }
                                }

                                return `${hour}:${minute} ${period}`;
                            };

                            const convertedTime = convertTo12HourFormat(time);
                            const endconvertedTime =
                                endconvertTo12HourFormat(endtime);
                            return (
                                <>
                                    <li>
                                        <span className="work-day">
                                            {e?.day}
                                        </span>
                                        <span className="work-hours">
                                            {convertedTime} - {endconvertedTime}
                                        </span>
                                    </li>
                                </>
                            );
                        })}
                    {/* <li>
                        <span className="work-day">Wednesday</span>
                        <span className="work-hours">7:00 - 17:00</span>
                    </li>
                    <li>
                        <span className="work-day">Thursday</span>
                        <span className="work-hours">7:00 - 17:00</span>
                    </li>
                    <li>
                        <span className="work-day">Friday</span>
                        <span className="work-hours">7:00 - 17:00</span>
                    </li>
                    <li>
                        <span className="work-day">Saturday</span>
                        <span className="work-hours">9:00 - 11:00</span>
                    </li>
                    <li>
                        <span className="work-day">Sunday</span>
                        <span className="work-hours">9:00 - 11:00</span>
                    </li> */}
                </ul>
                <Link className="btn-theme btn-white">Contact Us</Link>
            </div>
        </div>
    );
};

export default ProSidebarWorkingTime;
