import React, { useEffect, useState } from "react";
import SectionTitle from "../../components/section-title";
import ServiceItem from "../../components/service-item";
import { axiosInstance } from "../../config/https";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import ProServiceItem from "../../components/service-item/Proindex";
import { useTranslation } from "react-i18next";
const ServiceContainer = () => {
    const [loader, setLoader] = useState(false);
    const [inpsearch, setInpsearch] = useState("");
    const [type, settype] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [professionals, setProfessionals] = useState([]);
    const history = useHistory();
    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    useEffect(() => {
        if (!localStorage.getItem("accessToken")) {
            history.push("/login");
        } else {
            setLoader(true);
            axiosInstance
                .get(`user/professionals?user_id=${userDetails.id}`)
                .then((e) => {
                    if (e.data.status === false) {
                        toast.error(e.data.error);
                        history.push("/Packages");
                        setLoader(false);
                    }
                    if (e.data.status === true) {
                        console.log(e);
                        setLoader(false);
                        setProfessionals(e?.data?.data);
                        setFilteredData(e?.data?.data);
                    } else {
                        setLoader(false);
                    }
                })
                .catch((e) => setLoader(false));
        }
    }, []);

    const searchData = () => {
        if (inpsearch !== "" || type !== "") {
            const filtered = professionals.filter(
                (item) =>
                    item.fname
                        .toLowerCase()
                        .includes(inpsearch.toLowerCase()) ||
                    item.email
                        .toLowerCase()
                        .includes(inpsearch.toLowerCase()) ||
                    item.type.toLowerCase().includes(type.toLowerCase())
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(professionals);
        }
    };
    console.log(filteredData);
    const { t } = useTranslation();

    return (
        <>
            {loader ? <div className="loader"></div> : null}
            <div className="service-area p-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classOption="text-center"
                                subTitle={t("our_services")}
                                title={`<span>${t(
                                    `search_best_professionals`
                                ).replace(/_/g, " ")}</span> ${t(
                                    `for_your_health`
                                )}`}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-12">
                        <div className="float-end mb-3">
                            <button className="btn btn-success w-100">
                                <i className="icofont-filter"></i>
                            </button>
                        </div>
                    </div> */}
                        <div className="col-md-6">
                            <input
                                placeholder="Search here...."
                                type="search"
                                className="form-control mb-3"
                                onChange={(e) => setInpsearch(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4">
                            <select
                                className="form-control mb-3"
                                onChange={(e) => settype(e.target.value)}
                            >
                                <option selected hidden disabled>
                                    Select Your Professional Type...
                                </option>
                                <option value=""></option>
                                <option value="RN">RN</option>
                                <option value="CNA">CNA</option>
                                <option value="HHA">HHA</option>
                                <option value="AMADE ILAVE">AMADE ILAVE</option>
                                <option value="NUTNCIONISTA">
                                    NUTNCIONISTA
                                </option>
                                <option value="TRABAJADORA SOCIAL">
                                    TRABAJADORA SOCIAL
                                </option>
                            </select>
                        </div>

                        <div className="col-md-2">
                            <button
                                className="btn btn-primary w-100"
                                onClick={searchData}
                            >
                                Search &nbsp;
                                <i class="icofont-search-2 " />
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row service-style2">
                                {filteredData.length > 0 ? (
                                    filteredData
                                        .slice(0, 9)
                                        .map((single, key) => (
                                            <ProServiceItem
                                                key={key}
                                                id={single.id}
                                                img={
                                                    single?.image === null
                                                        ? "/images/nurse(1).jpg"
                                                        : single?.image
                                                }
                                                title={
                                                    single.fname + single.lname
                                                }
                                                email={single.email}
                                                address={single.type}
                                            />
                                        ))
                                ) : (
                                    <p className="capitalizing">
                                       {t( 'no_data_found')}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceContainer;
