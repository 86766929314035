import React from "react";
import { Link } from "react-router-dom";
import "./LoginPage.css";
import { useState } from "react";
import { axiosInstance } from "../config/https";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
// import Image from "/img/nurse.png";
const Signup = () => {
    const [passShow, setPassshow] = useState(false);
    const [fname, setFname] = useState("");
    const [lname, setlname] = useState("");
    const [image, setImage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const isSignUp = () => {
        setLoader(true);
        const formdata = new FormData();
        formdata.append("fname", fname);
        formdata.append("lname", lname);
        formdata.append("email", email);
        formdata.append("password", password);
        formdata.append("image", image[0]);
        axiosInstance
            .post(`users`, formdata)
            .then((e) => {
                console.log(e);
                if (e.data.status === true) {
                    toast.success(e.data.response);
                    setLoader(false);

                    history.push("/");
                } else {
                    toast.error(e.data.error);
                    setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
                console.log(error, "error");
            });
    };
    const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

    return (
        <div
            style={{
                backgroundImage: "url(/img/hospital.jpg)",
                height: "100vh",
                backgroundSize: "cover",
                backgroundPosition: `131px -137px`,
            }}
        >
            <div className="container">
                {!disclaimerAccepted && (
                    <div className="disclaimer-overlay">
                        <div
                            className="disclaimer-content"
                        >
                            <div className="container">
                                {/* <h3 className="text-center capitalizing home-text">
                                    ACUERDO DE LICENCIA DE USUARIO FINAL (EULA):
                                </h3>
                                <h3 className="text-center capitalizing home-text">
                                    POR FAVOR LEA CUIDADOSAMENTE LOS SIGUIENTES
                                    TÉRMINOS Y CONDICIONES ANTES DE HACER CLIC
                                    EN EL BOTÓN “ACEPTO”, DESCARGAR O UTILIZAR
                                    EL SITIO WEB: AVANZANDO JUNTOS.
                                </h3>
                                <p className="home-text">
                                    <span className="fw-bold">
                                        INTERPRETACIÓN Y DEFINICIONES:
                                    </span>
                                    Las palabras cuya letra inicial se escribe
                                    con mayúscula tienen significados definidos
                                    bajo las siguientes condiciones. Las
                                    siguientes definiciones tendrán el mismo
                                    significado independientemente de que
                                    aparezcan en singular o en plural.
                                </p>
                                <p className=" home-text">
                                    <span className="fw-bold">
                                        DEFINICIONES:
                                    </span>
                                    A los efectos de este ACUERDO DE LICENCIA DE
                                    USUARIO FINAL:
                                    <br />
                                    <span className="fw-bold">
                                        1. Acuerdo:
                                    </span>{" "}
                                    significa este Acuerdo de licencia de
                                    Usuario final que forma el acuerdo completo
                                    entre Usted y Avanzando Juntos con respecto
                                    al uso del sitio web.
                                    <br />
                                    <span className="fw-bold">
                                        2. Sitio Web:
                                    </span>
                                    significa el programa informático de
                                    desarrollo front-end y back-end denominado
                                    Avanzando Juntos, proporcionado por
                                    Avanzando Juntos, utilizado y suscrito por
                                    Usted. uso del sitio web.
                                    <br />
                                    <span className="fw-bold">3. Empresa:</span>
                                    (referida como el "Sitio web", "Compañía",
                                    "Nosotros", "Nos" o "Nuestro" en este
                                    Acuerdo) se refiere a Avanzando Juntos.{" "}
                                    <br />
                                    <span className="fw-bold">
                                        4. Contenido:
                                    </span>
                                    (referida como el "Sitio web", "Compañía",
                                    "Nosotros", "Nos" o "Nuestro" en este
                                    Acuerdo) se refiere a Avanzando Juntos. se
                                    refiere a Avanzando Juntos. <br />
                                    <span className="fw-bold">
                                        5. Dispositivo:
                                    </span>
                                    significa cualquier dispositivo que pueda
                                    acceder al sitio web, como una computadora,
                                    un teléfono celular o una tableta digital.
                                    Avanzando Juntos. <br />
                                    <span className="fw-bold">
                                        6. Servicios de terceros:
                                    </span>
                                    se refiere a cualquier servicio o contenido
                                    (incluidos datos, información, aplicaciones
                                    y otros servicios de productos)
                                    proporcionado por un tercero que puede
                                    mostrarse, incluirse o ponerse a disposición
                                    en el sitio web. Avanzando Juntos. <br />
                                    <span className="fw-bold">
                                        7. Usted/su:
                                    </span>
                                    significa la persona que accede al sitio web
                                    o la empresa u otra entidad legal en nombre
                                    de la cual dicha persona accede o utiliza el
                                    sitio web, según corresponda. Juntos. <br />
                                    <span className="fw-bold">
                                        8. Actividades Pagadas:
                                    </span>
                                    significa todas y cada una de las cuotas de
                                    suscripción, paquetes o anuncios pagados
                                    provistos por el Sitio Web Avanzando Juntos.
                                </p>
                                <h4 className="home-text">RECONOCIMIENTO:</h4>
                                <p className="home-text">
                                    Al hacer clic en el botón "Acepto", acceder
                                    o utilizar el sitio web, usted acepta estar
                                    sujeto a los términos y condiciones de este
                                    Acuerdo. Si no está de acuerdo con los
                                    términos del Acuerdo, NO HAGA CLIC EN EL
                                    BOTÓN "ACEPTO", NO ACCEDA NI UTILICE EL
                                    SITIO WEB. Este Acuerdo es un documento
                                    legal entre Usted y la empresa y rige su uso
                                    del Sitio web que Avanzando Juntos pone a su
                                    disposición.
                                </p>
                                <p className="home-text">
                                    Al acceder o utilizar el sitio web, usted
                                    acepta los siguientes términos y condiciones
                                    y está legalmente obligado por ellos, como
                                    si usted y nosotros hubiéramos firmado un
                                    contrato por escrito. Revise los términos y
                                    condiciones periódicamente, ya que están
                                    sujetos a modificación, alteración o
                                    actualización en cualquier momento y a la
                                    entera discreción de Avanzando Juntos. Si no
                                    está de acuerdo con algún término o
                                    condición, no debe usar ni acceder a este
                                    sitio.
                                </p>
                                <p className="home-text">
                                    Este acuerdo es entre Usted y Avanzando
                                    Juntos. Su uso es estrictamente para uso de
                                    acuerdo con los términos de este Acuerdo.
                                    Usted declara que es mayor de 18 años. La
                                    empresa no dirige su Contenido a niños o
                                    adolescentes menores de 18 años, y la
                                    Empresa no permite que ningún usuario menor
                                    de 18 años utilice el sitio web.
                                </p>
                                <p className="home-text">
                                    <ul>
                                        Usted acepta no realizar y acepta no
                                        permitir que otros realicen lo
                                        siguiente: sitio web.
                                        <li
                                            style={{
                                                listStyle: "unset",
                                            }}
                                        >
                                            Modificar, realizar trabajos
                                            derivados, desensamblar, descifrar,
                                            compilar o aplicar ingeniería
                                            inversa a cualquier parte del
                                        </li>
                                        <li
                                            style={{
                                                listStyle: "unset",
                                            }}
                                        >
                                            Eliminar, alterar u ocultar
                                            cualquier aviso de propiedad
                                            (incluido cualquier aviso de
                                            derechos de autor o marca
                                            registrada) del sitio web o sus
                                            afiliados, socios o proveedores del
                                            sitio web.
                                        </li>
                                    </ul>
                                </p> */}
                                <p align="center">
                                    <strong>
                                        ACUERDO DE LICENCIA DE USUARIO FINAL
                                        (EULA):
                                    </strong>
                                </p>
                                <p align="center"></p>
                                <p align="center">
                                    <strong>
                                        POR FAVOR LEA CUIDADOSAMENTE LOS
                                        SIGUIENTES T&Eacute;RMINOS Y CONDICIONES
                                        ANTES DE HACER CLIC EN EL BOT&Oacute;N
                                        &ldquo;ACEPTO&rdquo;, DESCARGAR O
                                        UTILIZAR EL SITIO WEB: AVANZANDO JUNTOS
                                    </strong>
                                    .
                                </p>
                                <p className="home-text">
                                    <strong>
                                        INTERPRETACI&Oacute;N Y DEFINICIONES:{" "}
                                    </strong>
                                    Las palabras cuya letra inicial se escribe
                                    con may&uacute;scula tienen significados
                                    definidos bajo las siguientes condiciones.
                                    Las siguientes definiciones tendr&aacute;n
                                    el mismo significado independientemente de
                                    que aparezcan en singular o en plural.
                                </p>
                                <p className="home-text">
                                    <strong>DEFINICIONES: </strong>A los efectos
                                    de este ACUERDO DE LICENCIA DE USUARIO
                                    FINAL:
                                </p>
                                <ol>
                                    <li>
                                        <strong>Acuerdo: </strong>significa este
                                        Acuerdo de licencia de Usuario final que
                                        forma el acuerdo completo entre Usted y
                                        Avanzando Juntos con respecto al uso del
                                        sitio web.
                                    </li>

                                    <li>
                                        <strong>Sitio Web: </strong>significa el
                                        programa inform&aacute;tico de
                                        desarrollo front-end y back-end
                                        denominado Avanzando Juntos,
                                        proporcionado por Avanzando Juntos,
                                        utilizado y suscrito por Usted.
                                    </li>

                                    <li>
                                        <strong>Empresa: </strong>(referida como
                                        el "Sitio web",
                                        "Compa&ntilde;&iacute;a", "Nosotros",
                                        "Nos" o "Nuestro" en este Acuerdo) se
                                        refiere a Avanzando Juntos.
                                    </li>

                                    <li>
                                        <strong>Contenido: </strong>se refiere
                                        al contexto, como texto,
                                        comunicaci&oacute;n, im&aacute;genes u
                                        otra informaci&oacute;n que usted puede
                                        publicar, cargar, vincular o poner a
                                        disposici&oacute;n de otro modo,
                                        independientemente de la forma de ese
                                        contenido.
                                    </li>

                                    <li>
                                        <strong>Dispositivo: </strong>significa
                                        cualquier dispositivo que pueda acceder
                                        al sitio web, como una computadora, un
                                        tel&eacute;fono celular o una tableta
                                        digital.
                                    </li>

                                    <li>
                                        <strong>Servicios de terceros:</strong>{" "}
                                        se refiere a cualquier servicio o
                                        contenido (incluidos datos,
                                        informaci&oacute;n, aplicaciones y otros
                                        servicios de productos) proporcionado
                                        por un tercero que puede mostrarse,
                                        incluirse o ponerse a disposici&oacute;n
                                        en el sitio web.
                                    </li>

                                    <li>
                                        <strong>Usted/su:</strong> significa la
                                        persona que accede al sitio web o la
                                        empresa u otra entidad legal en nombre
                                        de la cual dicha persona accede o
                                        utiliza el sitio web, seg&uacute;n
                                        corresponda.
                                    </li>

                                    <li>
                                        <strong>Actividades Pagadas: </strong>
                                        significa todas y cada una de las cuotas
                                        de suscripci&oacute;n, paquetes o
                                        anuncios pagados provistos por el Sitio
                                        Web Avanzando Juntos.
                                    </li>
                                </ol>
                                <p className="home-text"></p>
                                <p className="home-text">
                                    <strong>RECONOCIMIENTO:</strong>
                                </p>
                                <p className="home-text">
                                    Al hacer clic en el bot&oacute;n "Acepto",
                                    acceder o utilizar el sitio web, usted
                                    acepta estar sujeto a los t&eacute;rminos y
                                    condiciones de este Acuerdo. Si no
                                    est&aacute; de acuerdo con los
                                    t&eacute;rminos del Acuerdo, NO HAGA CLIC EN
                                    EL BOT&Oacute;N "ACEPTO", NO ACCEDA NI
                                    UTILICE EL SITIO WEB. Este Acuerdo es un
                                    documento legal entre Usted y la empresa y
                                    rige su uso del Sitio web que Avanzando
                                    Juntos pone a su disposici&oacute;n. .
                                </p>
                                <p className="home-text">
                                    Al acceder o utilizar el sitio web, usted
                                    acepta los siguientes t&eacute;rminos y
                                    condiciones y est&aacute; legalmente
                                    obligado por ellos, como si usted y nosotros
                                    hubi&eacute;ramos firmado un contrato por
                                    escrito. Revise los t&eacute;rminos y
                                    condiciones peri&oacute;dicamente, ya que
                                    est&aacute;n sujetos a modificaci&oacute;n,
                                    alteraci&oacute;n o actualizaci&oacute;n en
                                    cualquier momento y a la entera
                                    discreci&oacute;n de Avanzando Juntos. Si no
                                    est&aacute; de acuerdo con alg&uacute;n
                                    t&eacute;rmino o condici&oacute;n, no debe
                                    usar ni acceder a este sitio.
                                </p>
                                <p className="home-text">
                                    Este acuerdo es entre Usted y Avanzando
                                    Juntos. Su uso es estrictamente para uso de
                                    acuerdo con los t&eacute;rminos de este
                                    Acuerdo. Usted declara que es mayor de 18
                                    a&ntilde;os. La empresa no dirige su
                                    Contenido a ni&ntilde;os o adolescentes
                                    menores de 18 a&ntilde;os, y la Empresa no
                                    permite que ning&uacute;n usuario menor de
                                    18 a&ntilde;os utilice el sitio web.
                                </p>
                                <p className="home-text">
                                    Usted acepta no realizar y acepta no
                                    permitir que otros realicen lo siguiente:
                                </p>
                                <ul>
                                    <li>
                                        Modificar, realizar trabajos derivados,
                                        desensamblar, descifrar, compilar o
                                        aplicar ingenier&iacute;a inversa a
                                        cualquier parte del sitio web.
                                    </li>
                                    <li>
                                        Eliminar, alterar u ocultar cualquier
                                        aviso de propiedad (incluido cualquier
                                        aviso de derechos de autor o marca
                                        registrada) del sitio web o sus
                                        afiliados, socios o proveedores del
                                        sitio web.
                                    </li>
                                </ul>
                                <p className="home-text">
                                    <strong>CAMBIOS AL ACUERDO: </strong>
                                    Avanzando Juntos se reserva el derecho, a su
                                    sola discreci&oacute;n, de modificar o
                                    reemplazar este Acuerdo en cualquier
                                    momento. Si una revisi&oacute;n es material,
                                    le proporcionaremos un aviso de al menos 30
                                    d&iacute;as antes de que entren en vigencia
                                    los nuevos t&eacute;rminos. Lo que
                                    constituye un cambio material se
                                    determinar&aacute; a discreci&oacute;n
                                    exclusiva de Avanzando Juntos. Al continuar
                                    accediendo o utilizando el sitio web
                                    Avanzando Juntos despu&eacute;s de que las
                                    revisiones entren en vigencia, usted acepta
                                    estar sujeto a los t&eacute;rminos
                                    revisados. Si no est&aacute; de acuerdo con
                                    los nuevos t&eacute;rminos, ya no
                                    est&aacute; autorizado a utilizar el sitio
                                    web.
                                </p>
                                <p className="home-text">
                                    <strong>LEY APLICABLE: </strong>Las leyes
                                    del Territorio, excluyendo sus conflictos de
                                    normas legales, regir&aacute;n este Acuerdo
                                    y Su uso del Sitio web. Su uso del sitio web
                                    tambi&eacute;n puede estar sujeto a otras
                                    leyes locales, estatales, nacionales o
                                    internacionales.
                                </p>
                                <p className="home-text">
                                    <strong>ACUERDO COMPLETO:</strong> El
                                    Acuerdo constituye el acuerdo completo entre
                                    Usted y Avanzando Juntos con respecto a su
                                    uso del sitio web y reemplaza todos los
                                    acuerdos escritos u orales anteriores y
                                    contempor&aacute;neos entre Usted y
                                    Avanzando Juntos.
                                </p>
                                <p className="home-text">
                                    Usted puede estar sujeto a t&eacute;rminos y
                                    condiciones adicionales que se aplican
                                    cuando usa o compra cualquiera de los
                                    servicios de Avanzando Juntos ofrecidos en
                                    el sitio web, que Avanzando Juntos le
                                    proporcionar&aacute; en el momento de dicho
                                    uso o compra.
                                </p>
                                <p className="home-text">
                                    <strong>RESTRICCIONES DE CONTENIDO:</strong>
                                </p>
                                <p className="home-text">
                                    Avanzando Juntos enfatiza que no asume
                                    responsabilidad por las entradas,
                                    informaci&oacute;n o contenido proporcionado
                                    por Usted en el Sitio Web. Es importante
                                    tener en cuenta que usted (el usuario)
                                    comprende y acepta expresamente que es el
                                    &uacute;nico responsable de la
                                    informaci&oacute;n, el contenido, el
                                    material y todas y cada una de las
                                    actividades que env&iacute;e al sitio web,
                                    as&iacute; como cualquier actividad que
                                    tenga lugar bajo su cuenta. Esto se aplica
                                    tanto a sus propias acciones como a las
                                    realizadas por cualquier tercero que acceda
                                    a su cuenta. Usted es totalmente responsable
                                    de dicha informaci&oacute;n,
                                    comunicaci&oacute;n o material, incluida su
                                    legalidad, confiabilidad, adecuaci&oacute;n,
                                    originalidad o derechos de autor. El sitio
                                    web Avanzando Juntos se exime expresamente
                                    de responsabilidad al respecto.
                                </p>
                                <p className="home-text">
                                    <strong>
                                        RESPONSABILIDAD DE LOS COLABORADORES:
                                    </strong>
                                </p>
                                <p className="home-text">
                                    De acuerdo con las restricciones de
                                    contenido del sitio web, usted est&aacute;
                                    obligado a asegurarse de que cualquier
                                    informaci&oacute;n, comunicaci&oacute;n o
                                    material que publique o cargue en el sitio,
                                    as&iacute; como cualquier actividad que
                                    tenga lugar en su cuenta, cumpla con estos
                                    requisitos legales. Esto incluye verificar
                                    que el Contenido sea preciso y veraz, que no
                                    sea enga&ntilde;oso y que no contenga ni
                                    transmita contenido da&ntilde;ino o
                                    destructivo. Usted es responsable de
                                    verificar la legalidad, confiabilidad,
                                    idoneidad, originalidad y el estado de los
                                    derechos de autor del Contenido que
                                    contribuye. Avanzando Juntos recomienda a
                                    los usuarios la diligencia y prudencia para
                                    garantizar el cumplimiento de estas
                                    obligaciones legales.
                                </p>
                                <p className="home-text">
                                    Tiene prohibido cargar, agregar o transmitir
                                    cualquier contenido, informaci&oacute;n o
                                    actividad que sea amenazante, calumnioso,
                                    difamatorio, obsceno, ilegal, ofensivo, con
                                    la intenci&oacute;n de disgustar o de otra
                                    manera objetable. Esto incluye abstenerse de
                                    publicar material ilegal, profano o
                                    pornogr&aacute;fico. No comparta
                                    ning&uacute;n material que pueda alentar una
                                    conducta delictiva, dar lugar a
                                    responsabilidad civil o violar cualquier
                                    ley. Los ejemplos de contenido objetable
                                    incluyen, pero no se limitan a:
                                </p>
                                <p className="home-text">
                                    Contenido que contenga o instale virus,
                                    gusanos, malware, troyanos u otro contenido
                                    disruptivo o da&ntilde;ino. Contenido que
                                    infringe los derechos de propiedad de otros,
                                    como patentes, marcas registradas, secretos
                                    comerciales, derechos de autor o derechos de
                                    publicidad. Publicar informaci&oacute;n
                                    falsa, violar la privacidad de otros o
                                    hacerse pasar por personas o entidades,
                                    incluida la empresa y sus empleados.
                                    Contenido difamatorio, discriminatorio o
                                    mezquino que incluye referencias o
                                    comentarios despectivos sobre
                                    religi&oacute;n, raza, orientaci&oacute;n
                                    sexual, g&eacute;nero, origen
                                    nacional/&eacute;tnico u otros grupos
                                    objetivo. Contenido ilegal o contenido que
                                    promueva actividades ilegales.
                                </p>
                                <p className="home-text">
                                    Spam, contenido generado por
                                    m&aacute;quinas, publicidad no autorizada,
                                    cartas en cadena, solicitudes no solicitadas
                                    o cualquier forma de loter&iacute;a o
                                    apuestas.
                                </p>
                                <p className="home-text">
                                    Avanzando Juntos tiene el derecho, pero no
                                    la obligaci&oacute;n, de determinar si
                                    alg&uacute;n Contenido es apropiado y cumple
                                    con este Acuerdo. La empresa puede rechazar
                                    o eliminar cualquier Contenido y formatear y
                                    editar seg&uacute;n sea necesario. Avanzando
                                    Juntos tambi&eacute;n tiene la autoridad
                                    para limitar o revocar el uso del sitio web
                                    si carga, ingresa o crea informaci&oacute;n
                                    objetable.
                                </p>
                                <p className="home-text">
                                    El contenido, la comunicaci&oacute;n o el
                                    material que publique o transmita al sitio
                                    web Avanzando Juntos a trav&eacute;s de
                                    Internet es, y ser&aacute; tratado como no
                                    confidencial y no patentado, y ser&aacute;
                                    para siempre propiedad exclusiva de
                                    Avanzando Juntos. Al publicar, cargar o
                                    transmitir cualquier informaci&oacute;n,
                                    comunicaci&oacute;n o material a este sitio,
                                    usted acepta que Avanzando Juntos no
                                    ser&aacute; responsable ante usted por su
                                    uso y acepta que Avanzando Juntos
                                    ser&aacute; libre de usar dicha
                                    informaci&oacute;n, comunicaci&oacute;n o
                                    material. para cualquier prop&oacute;sito
                                    que sea.
                                </p>
                                <p className="home-text">
                                    Avanzando Juntos no alquila, vende o
                                    intercambia informaci&oacute;n sobre sus
                                    usuarios. Las direcciones de correo
                                    electr&oacute;nico enviadas a Avanzando
                                    Juntos se mantienen confidenciales y no se
                                    vender&aacute;n ni distribuir&aacute;n a
                                    terceros.
                                </p>
                                <p className="home-text">
                                    Debe tener en cuenta que divulga
                                    voluntariamente informaci&oacute;n personal
                                    (por ejemplo, pero no limitado a: nombre de
                                    usuario, correo electr&oacute;nico,
                                    n&uacute;mero de tel&eacute;fono) en
                                    cualquier parte del sitio web, esa
                                    informaci&oacute;n puede ser recopilada y
                                    utilizada por otros y puede resultar en
                                    mensajes no solicitados de otras partes.
                                </p>
                                <p className="home-text">
                                    <strong>RECONOCIMIENTO DE RIESGO: </strong>
                                    Al utilizar el sitio web Avanzando Juntos,
                                    usted reconoce y acepta que la empresa no
                                    puede controlar todo el contenido cargado,
                                    publicado o ingresado por usted, el usuario
                                    o terceros. Por lo tanto, acepta el riesgo
                                    asociado con el uso del sitio web. Usted
                                    comprende que puede haber contenido en el
                                    sitio web que puede encontrar ofensivo,
                                    indecente, incorrecto u objetable. Usted
                                    acepta que la empresa no ser&aacute;
                                    responsable de ning&uacute;n contenido,
                                    incluidos los errores u omisiones en el
                                    contenido, o cualquier p&eacute;rdida o
                                    da&ntilde;o incurrido como resultado de su
                                    uso de cualquier contenido.
                                </p>
                                <p className="home-text">
                                    <strong>SUS SUGERENCIAS: </strong>Cualquier
                                    retroalimentaci&oacute;n, comentario, idea,
                                    mejora o sugerencia proporcionada por Usted
                                    a Avanzando Juntos con respecto a la empresa
                                    se considerar&aacute; no confidencial y no
                                    propietaria, Avanzando Juntos tendr&aacute;
                                    el derecho irrestricto de usar, copiar,
                                    modificar, publicar , redistribuya e
                                    incorpore estas sugerencias para cualquier
                                    prop&oacute;sito y de cualquier manera sin
                                    ninguna obligaci&oacute;n de otorgarle
                                    cr&eacute;dito o compensaci&oacute;n.
                                </p>
                                <p className="home-text">&nbsp;&nbsp;</p>
                                <p className="home-text">
                                    <strong>TERMINACI&Oacute;N: </strong>
                                    Avanzando Juntos se reserva la autoridad
                                    para alterar, suspender o cancelar, ya sea
                                    de manera temporal o permanente, el sitio
                                    web o cualquier servicio asociado, con o sin
                                    notificaci&oacute;n previa, y sin
                                    notificaci&oacute;n previa, y sin que se
                                    produzca ninguna responsabilidad hacia
                                    usted.
                                </p>
                                <p className="home-text">
                                    Si desea cancelar su cuenta (si tiene una),
                                    simplemente puede dejar de usar los
                                    Servicios y enviar un correo
                                    electr&oacute;nico a
                                    Avanzandojuntos.pr@gmail.com. Todas las
                                    disposiciones de esta cuenta que, por su
                                    naturaleza, deber&iacute;an sobrevivir a la
                                    rescisi&oacute;n, incluidas, entre otras,
                                    las disposiciones de propiedad, las
                                    exenciones de garant&iacute;a, la
                                    indemnizaci&oacute;n y las limitaciones de
                                    responsabilidad.
                                </p>
                                <p className="home-text">
                                    <strong>
                                        RENUNCIA DE RESPONSABILIDAD:
                                    </strong>
                                </p>
                                <p className="home-text">
                                    Avanzando Juntos no ofrece ninguna
                                    garant&iacute;a con respecto al sitio web.
                                    Cuando se utiliza informaci&oacute;n,
                                    contenido, software o productos que se
                                    encuentran en Internet, existen riesgos
                                    inherentes. Avanzando Juntos le aconseja y
                                    advierte que comprenda total y completamente
                                    estos riesgos antes de acceder, usar,
                                    recuperar o confiar en cualquier cosa que
                                    encuentre en Internet. Al visitar este
                                    sitio, usted asume toda la responsabilidad y
                                    el riesgo asociado con el uso de este sitio.
                                </p>
                                <p className="home-text">
                                    <strong>
                                        LIMITACIONES DE RESPONSABILIDAD:
                                    </strong>
                                </p>
                                <p className="home-text">
                                    Avanzando Juntos no acepta ninguna
                                    responsabilidad por cualquier problema que
                                    surja de su uso de este sitio, incluidas
                                    infecciones, contaminaciones, virus, demoras
                                    operativas, fallas, errores, omisiones,
                                    interrupciones o defectos relacionados con
                                    el material proporcionado o
                                    contaminaci&oacute;n del sitio o uso de este
                                    sitio . El sitio web Avanzando Juntos no
                                    ser&aacute; responsable ante usted por
                                    cualquier falla o retraso causado por
                                    circunstancias fuera de su control
                                    razonable.
                                </p>
                                <p className="home-text">
                                    Bajo ninguna circunstancia Avanzando Juntos
                                    ser&aacute; responsable por cualquier
                                    da&ntilde;o especial, indirecto, incidental,
                                    consecuencial o cualquier otro da&ntilde;o
                                    de cualquier tipo, que resulte de una causa.
                                    Esto incluye, entre otros, la p&eacute;rdida
                                    de uso, la p&eacute;rdida de ganancias
                                    resultante de cualquier causa, lo que
                                    incluye, entre otros, la p&eacute;rdida de
                                    uso, la p&eacute;rdida de ganancias, la
                                    p&eacute;rdida de datos o la p&eacute;rdida
                                    de oportunidades comerciales. En
                                    ning&uacute;n caso, Avanzando Juntos o sus
                                    proveedores ser&aacute;n responsables ante
                                    Usted (incluidos sus clientes, afiliados,
                                    sucesores o herederos) por ninguna y todas
                                    las reclamaciones, da&ntilde;os,
                                    p&eacute;rdidas y causas que surjan de este
                                    acuerdo bajo cualquier responsabilidad
                                    contractual, negligente, estricta o otra
                                    teor&iacute;a legal o equitativa. Esto
                                    incluye, entre otros, da&ntilde;os
                                    especiales, incidentales o consecuentes;
                                    Interrupci&oacute;n del uso o p&eacute;rdida
                                    o corrupci&oacute;n de datos; el costo de
                                    adquirir productos o servicios sustitutos.
                                </p>
                                <p className="home-text">
                                    <strong>INDEMNIZACI&Oacute;N:</strong>
                                </p>
                                <p className="home-text">
                                    Seg&uacute;n los t&eacute;rminos de este
                                    acuerdo, usted acepta indemnizar y eximir a
                                    Avanzando Juntos y a sus proveedores, junto
                                    con sus respectivos directores,
                                    funcionarios, empleados y agentes, de
                                    cualquier reclamo y gasto, incluidos los
                                    honorarios de abogados, que surjan de (1 )
                                    Su uso de la Plataforma, incluida cualquier
                                    violaci&oacute;n de esta Pol&iacute;tica de
                                    privacidad, T&eacute;rminos de servicio o
                                    leyes aplicables. (2) La
                                    recopilaci&oacute;n, el uso, el
                                    almacenamiento o la divulgaci&oacute;n de
                                    informaci&oacute;n personal de acuerdo con
                                    esta Pol&iacute;tica de privacidad, siempre
                                    que cumpla con las leyes aplicables. (3)
                                    Cualquier contenido o material que
                                    env&iacute;e a trav&eacute;s de la
                                    Plataforma, y ​​cualquier reclamo o disputa
                                    con terceros. (4) Cualquier atenci&oacute;n
                                    brindada a cualquier usuario o persona(s)
                                    para quienes Usted busca atenci&oacute;n a
                                    trav&eacute;s de la plataforma. Avanzando
                                    Juntos solo facilita la comunicaci&oacute;n
                                    entre los usuarios y no brinda ni se
                                    responsabiliza de las negociaciones o la
                                    atenci&oacute;n en s&iacute;. Los usuarios
                                    son los &uacute;nicos responsables de
                                    evaluar y seleccionar los proveedores de
                                    atenci&oacute;n y garantizar la idoneidad y
                                    la calidad de la atenci&oacute;n brindada.
                                    (4) Cualquier proveedor, equipo o
                                    instalaci&oacute;n de terceros utilizados
                                    durante el horario comercial asignado o
                                    fuera de este en relaci&oacute;n con
                                    cualquier arreglo realizado en la plataforma
                                    o fuera de la Plataforma. Avanzando Juntos
                                    no respalda, controla ni se hace responsable
                                    de los proveedores, equipos o instalaciones,
                                    y usted es el &uacute;nico responsable de
                                    cualquier interacci&oacute;n, acuerdo o
                                    arreglo que se haga con ellos. Esta
                                    indemnizaci&oacute;n se aplica
                                    independientemente de si la negligencia de
                                    Avanzando Juntos o de sus proveedores es la
                                    causa o si se debe a otras circunstancias.
                                    (6) Acceso o uso no autorizado de su cuenta.
                                </p>
                                <p align="center">
                                    <strong>
                                        POL&Iacute;TICA DE PRIVACIDAD DE
                                        AVANZANDO JUNTOS: PLATAFORMA DE
                                        COMUNICACI&Oacute;N, COMPROMISO Y
                                        SERVICIO.
                                    </strong>
                                </p>
                                <p align="center">&nbsp;</p>
                                <p className="home-text">
                                    <strong>
                                        &Uacute;LTIMA ACTUALIZACI&Oacute;N: 23
                                        DE JUNIO DE 2023
                                    </strong>
                                </p>
                                <p className="home-text">
                                    Gracias por usar Avanzando Juntos ("la
                                    Plataforma"). Estamos comprometidos a
                                    proteger su privacidad y garantizar la
                                    seguridad de su informaci&oacute;n personal.
                                    Esta Pol&iacute;tica de privacidad describe
                                    c&oacute;mo recopilamos, usamos y divulgamos
                                    informaci&oacute;n de identificaci&oacute;n
                                    personal e informaci&oacute;n de
                                    identificaci&oacute;n no personal cuando
                                    utiliza nuestra Plataforma. Al acceder o
                                    utilizar www.avanzando Juntos.net, usted
                                    acepta los t&eacute;rminos descritos en esta
                                    pol&iacute;tica.
                                </p>
                                <p className="home-text">
                                    <strong>
                                        Informaci&oacute;n que recopilamos:
                                    </strong>
                                </p>
                                <p className="home-text">
                                    a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Informaci&oacute;n de identificaci&oacute;n
                                    personal (PII): podemos recopilar los
                                    siguientes tipos de PII de usted:
                                </p>
                                <ul>
                                    <li>
                                        Nombre, direcci&oacute;n de correo
                                        electr&oacute;nico, direcci&oacute;n
                                        postal, n&uacute;mero de tel&eacute;fono
                                        u otra informaci&oacute;n de contacto
                                        proporcionada durante el registro o la
                                        creaci&oacute;n del perfil.
                                    </li>
                                    <li>
                                        Informaci&oacute;n de pago necesaria
                                        para las transacciones realizadas a
                                        trav&eacute;s de la Plataforma.
                                    </li>
                                    <li>
                                        Cualquier informaci&oacute;n que
                                        proporcione voluntariamente a
                                        trav&eacute;s de mensajes, foros u otras
                                        funciones de comunicaci&oacute;n.
                                    </li>
                                </ul>
                                <p className="home-text">
                                    b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Informaci&oacute;n de identificaci&oacute;n
                                    no personal (Non-PII): podemos recopilar
                                    autom&aacute;ticamente los siguientes tipos
                                    de Non-PII:
                                </p>
                                <ul>
                                    <li>
                                        Informaci&oacute;n del dispositivo:
                                        direcci&oacute;n IP, tipo de navegador,
                                        sistema operativo y otra
                                        informaci&oacute;n t&eacute;cnica.
                                    </li>
                                    <li>
                                        Informaci&oacute;n de uso:
                                        p&aacute;ginas visitadas, enlaces en los
                                        que se hizo clic y otras acciones
                                        realizadas durante el uso de la
                                        Plataforma.
                                    </li>
                                    <li>
                                        Cookies y tecnolog&iacute;as similares:
                                        podemos usar cookies y
                                        tecnolog&iacute;as similares para
                                        mejorar su experiencia y recopilar
                                        cierta informaci&oacute;n sobre su uso
                                        de la Plataforma.
                                    </li>
                                </ul>
                                <p className="home-text">
                                    <strong>
                                        Uso de la informaci&oacute;n:
                                    </strong>
                                </p>
                                <ol>
                                    <li>
                                        Informaci&oacute;n de
                                        identificaci&oacute;n personal (PII):
                                        podemos usar su PII para proporcionar y
                                        personalizar nuestros servicios,
                                        comunicarnos con usted y responder a sus
                                        consultas. Su PII puede usarse para
                                        procesar transacciones y entregar
                                        productos o servicios que solicite.
                                        Podemos enviarle materiales
                                        promocionales o informaci&oacute;n sobre
                                        nuevas caracter&iacute;sticas, productos
                                        o servicios que creemos que pueden
                                        interesarle. Puede optar por no recibir
                                        dichas comunicaciones en cualquier
                                        momento. Podemos usar su PII para
                                        mejorar nuestra Plataforma, analizar
                                        tendencias de uso y realizar
                                        investigaciones.
                                    </li>

                                    <li>
                                        Informaci&oacute;n de
                                        identificaci&oacute;n no personal
                                        (Non-PII): Non-PII se utiliza
                                        principalmente con fines
                                        estad&iacute;sticos y anal&iacute;ticos,
                                        lo que nos permite comprender
                                        c&oacute;mo los usuarios
                                        interact&uacute;an con la Plataforma y
                                        mejorar su funcionalidad. Podemos usar
                                        Non-PII para personalizar y mejorar su
                                        experiencia, por ejemplo, proporcionando
                                        contenido espec&iacute;fico o
                                        recomendaciones basadas en sus patrones
                                        de uso.
                                    </li>
                                </ol>
                                <p className="home-text">
                                    <strong>
                                        Intercambio de informaci&oacute;n:
                                    </strong>
                                </p>
                                <ol>
                                    <li>
                                        Informaci&oacute;n de
                                        identificaci&oacute;n personal (PII, por
                                        sus siglas en ingl&eacute;s): podemos
                                        compartir su PII con proveedores de
                                        servicios externos de confianza que nos
                                        ayuden a brindar nuestros servicios,
                                        como procesadores de pagos o proveedores
                                        de alojamiento. Estos proveedores de
                                        servicios est&aacute;n obligados a
                                        proteger su informaci&oacute;n y solo la
                                        utilizan para los fines especificados
                                        por nosotros. Podemos compartir su PII
                                        si as&iacute; lo requiere la ley, un
                                        proceso legal o una solicitud del
                                        gobierno, o para proteger nuestros
                                        derechos, propiedad o seguridad, o los
                                        derechos, propiedad o seguridad de
                                        otros.
                                    </li>

                                    <li>
                                        Informaci&oacute;n de
                                        identificaci&oacute;n no personal
                                        (Non-PII): La Non-PII puede compartirse
                                        con terceros para diversos fines, como
                                        analizar patrones de uso o mejorar la
                                        Plataforma. Esta informacion no te
                                        identifica personalmente.
                                    </li>
                                </ol>
                                <p className="home-text">
                                    <strong>Seguridad de datos: </strong>Tomamos
                                    medidas razonables para proteger su
                                    informaci&oacute;n del acceso no autorizado,
                                    alteraci&oacute;n, divulgaci&oacute;n o
                                    destrucci&oacute;n. Sin embargo, tenga en
                                    cuenta que no se puede garantizar que la
                                    transmisi&oacute;n de datos a trav&eacute;s
                                    de Internet o el sistema de almacenamiento
                                    sea 100 % seguro.
                                </p>
                                <p className="home-text">
                                    <strong>
                                        Sitios web y servicios de terceros:{" "}
                                    </strong>
                                    nuestra plataforma puede contener enlaces a
                                    sitios web o servicios de terceros. Esta
                                    Pol&iacute;tica de privacidad no se aplica a
                                    esos sitios de terceros y le recomendamos
                                    que revise sus pol&iacute;ticas de
                                    privacidad. No somos responsables de las
                                    pr&aacute;cticas empleadas por estos sitios
                                    web o servicios.
                                </p>
                                <p className="home-text">
                                    <strong>
                                        Privacidad de los ni&ntilde;os:{" "}
                                    </strong>
                                    la plataforma est&aacute; destinada a
                                    personas que tengan al menos 18 a&ntilde;os.
                                    No recopilamos a sabiendas
                                    informaci&oacute;n personal de personas
                                    menores de 18 a&ntilde;os. Si tiene menos de
                                    18 a&ntilde;os, no use la Plataforma ni
                                    proporcione ninguna informaci&oacute;n
                                    personal. Si nos damos cuenta de que hemos
                                    recopilado inadvertidamente
                                    informaci&oacute;n de una persona menor de
                                    18 a&ntilde;os sin el consentimiento de los
                                    padres, tomaremos medidas para eliminar esa
                                    informaci&oacute;n de nuestros sistemas. Si
                                    cree que podemos haber recopilado
                                    informaci&oacute;n de una persona menor de
                                    18 a&ntilde;os, comun&iacute;quese con
                                    nosotros utilizando la informaci&oacute;n
                                    proporcionada al final de esta
                                    Pol&iacute;tica de privacidad.
                                </p>
                                <p className="home-text">
                                    Tenga en cuenta que si es menor de 18
                                    a&ntilde;os y usa la Plataforma sin la
                                    supervisi&oacute;n o el actualizar permiso
                                    apropiados, usted es el &uacute;nico
                                    responsable de cualquier divulgaci&oacute;n
                                    de su informaci&oacute;n personal y por la
                                    presente reconoce que no somos responsables
                                    de ninguna consecuencia o da&ntilde;o que
                                    surja de dicho uso.
                                </p>
                                <p className="home-text">
                                    <strong>Sus opciones</strong>: Acceso y
                                    actualizaci&oacute;n de su
                                    informaci&oacute;n: Tiene derecho a acceder
                                    y su informaci&oacute;n de
                                    identificaci&oacute;n personal que tenemos
                                    en nuestro poder. Puede revisar y modificar
                                    la informaci&oacute;n de su perfil
                                    accediendo a la configuraci&oacute;n de su
                                    cuenta en la Plataforma. Si necesita
                                    m&aacute;s ayuda, comun&iacute;quese con
                                    nosotros utilizando la informaci&oacute;n
                                    que se proporciona a continuaci&oacute;n.
                                    Preferencias de comunicaci&oacute;n: puede
                                    optar por no recibir nuestras comunicaciones
                                    promocionales siguiendo las instrucciones
                                    proporcionadas en la comunicaci&oacute;n o
                                    poni&eacute;ndose en contacto con nosotros
                                    directamente. Tenga en cuenta que incluso si
                                    opta por no recibir comunicaciones
                                    promocionales, a&uacute;n podemos enviarle
                                    mensajes no promocionales relacionados con
                                    su uso de la Plataforma.
                                </p>
                                <p className="home-text">
                                    <strong>
                                        Cambios a esta Pol&iacute;tica de
                                        Privacidad:{" "}
                                    </strong>
                                    Podemos actualizar esta Pol&iacute;tica de
                                    Privacidad de vez en cuando para reflejar
                                    cambios en nuestras pr&aacute;cticas o por
                                    otras razones operativas, legales o
                                    reglamentarias. Le notificaremos sobre
                                    cualquier cambio sustancial mediante la
                                    publicaci&oacute;n de la Pol&iacute;tica de
                                    privacidad actualizada en la Plataforma o
                                    por otros medios de comunicaci&oacute;n. Le
                                    recomendamos que revise esta Pol&iacute;tica
                                    de privacidad peri&oacute;dicamente para
                                    conocer las actualizaciones.
                                </p>
                                <p className="home-text">
                                    <strong>
                                        Comun&iacute;quese con nosotros:{" "}
                                    </strong>
                                    si tiene alguna pregunta, inquietud o
                                    solicitud con respecto a esta
                                    Pol&iacute;tica de privacidad o el manejo de
                                    su informaci&oacute;n personal, puede
                                    comunicarse con nosotros con la
                                    informaci&oacute;n a continuaci&oacute;n.
                                    Proporcione suficiente informaci&oacute;n
                                    para que podamos responder a su consulta o
                                    solicitud de manera adecuada.
                                </p>
                                <p className="home-text">
                                    Al usar Avanzando Juntos, usted reconoce que
                                    ha le&iacute;do y entendido esta
                                    Pol&iacute;tica de privacidad y acepta la
                                    recopilaci&oacute;n, el uso y la
                                    divulgaci&oacute;n de su informaci&oacute;n
                                    personal como se describe en este documento.
                                </p>
                                <p className="home-text">
                                    Tenga en cuenta que si tiene menos de 18
                                    a&ntilde;os, debe obtener el consentimiento
                                    de los padres antes de utilizar la
                                    Plataforma.
                                </p>
                                <p className="home-text">
                                    Gracias por su confianza en Avanzando
                                    Juntos.
                                </p>
                                <p className="home-text">
                                    Correo electr&oacute;nico:
                                    Avanzandojuntos.net
                                </p>
                                <p className="home-text">
                                    Tel&eacute;fono: 939-246-0206
                                </p>
                                <button
                                    className="float-end btn btn-primary"
                                    onClick={() => setDisclaimerAccepted(true)}
                                >
                                    I Accept It
                                </button>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                )}
                <div className="row ">
                    <div className="offset-md-3 col-md-6">
                        <div className="login-card shadow-lg rounded bg-transparent">
                            <div className="row ">
                                <div className="col-md-12 ">
                                    <Link to={"/"}>
                                        <img
                                            src="/img/logo.png"
                                            className=" w-100  rounded"
                                            alt=""
                                        />
                                    </Link>
                                    <h3 className="text-center text-white">
                                        Welcome to AvanZandoJuntos!
                                    </h3>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="text-white">
                                                    First Name
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            setFname(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="text-white">
                                                    Last Name
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            setlname(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="text-white">
                                                    Email
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text">
                                                        <i className="icofont-ui-user" />
                                                    </span>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            setEmail(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="text-white">
                                                    Password
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text">
                                                        <i className="icofont-ui-lock" />
                                                    </span>
                                                    <input
                                                        type={
                                                            passShow
                                                                ? "text"
                                                                : "password"
                                                        }
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            setPassword(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <span className="input-group-text">
                                                        <i
                                                            className="icofont-eye-alt"
                                                            onClick={() =>
                                                                setPassshow(
                                                                    !passShow
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="text-white">
                                                    Profile Picture
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            setImage(
                                                                e.target.files
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="offset-md-1 col-md-10 text-center ">
                                        <p className="text-white">
                                            You have an Account?
                                            <Link to={"/login"}>
                                                <i>
                                                    <u>login</u>
                                                </i>
                                            </Link>{" "}
                                        </p>
                                        <button
                                            className="btn btn-theme w-75"
                                            onClick={isSignUp}
                                        >
                                            SignUp{" "}
                                            {loader ? (
                                                <i className="icofont-spinner icofont-spin fs-3" />
                                            ) : null}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
